import React, { PureComponent } from "react";
import ChatContext from "../../components/ChatContext";
import {
  deleteChatCounter,
  deleteChatData,
  deleteMessage,
  deletePlanData,
  getPlanData,
  saveClientPlan,
} from "../../components/Dblovefield";

/*  to add footer code from chat to here start */
import Dropzone from "react-dropzone";
import "emoji-mart/css/emoji-mart.css";
import "../midle/smartComposeStyle.css";
import { Picker } from "emoji-mart";
// import db, { storageConnetion } from "../../../../config/firebase";
import firebase1 from "firebase/compat";
import autosize from "autosize";
import {
  MAIL_FUNCTION_URL,
  GATEWAY_URL,
  FUNCTION_API_KEY,
  GOOGLE_TRANSLATION_KEY,
  SWITCH_TO_CLOUD_FUNCTION,
} from "../../../../config/siteConfig";
import {
  getDateInUTC,
  getChatFileFolder,
  updateAgentChatData,
  getUserRole,
  nameIntials,
  isCallingEnable,
  CHAT_TYPES,
  getDeviceBrowser,
} from "../../components/Comman";
import { updateAgentLastActivityTime } from "../../../organization/Model/DbModel";
import {
  sendSMS,
  sendOrCreateVisitorSMS,
  sendOrCreateAgentChat,
  setCurrentChat,
  sendWhatsAppMessage,
  sendWhatsAppTemplate,
  getCallStatus,
  rejectCall,
  endCall,
} from "../../components/Twilio";
import {
  activityLog,
  getCannedText,
  addCannedText,
} from "../../../../services/service";
import firebaseServices from "./../../../../firebase";
/*  to add footer code from chat to here end */

import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { generateRandomId } from "../smartcompose/predictable/utils";
import { words } from "../smartcompose/words";
import Chat from "./chat";
const phrasePosition = "innerofdiv";

let autoKeyMessages = [];
//const activeSuggestion = false;
/*  to add footer code from chat to here end */

const validFileFormates = ["text/html", "text/rtf", "application/pdf"];

class Footer extends PureComponent {
  static contextType = ChatContext; // this.context will work when it defined as is
  //editorContainer = React.createRef();
  agent = localStorage.getItem("agent")
    ? JSON.parse(localStorage.getItem("agent"))
    : {};
  previousMessage = "";
  previousMsgCounter = 0;
  checkResizeRun = false;
  checkResizeRunSMS = false;
  checkResizeRunWApp = false;
  checkResizeRunMessanger = false;
  checkResizeRunTwitterDM = false;
  closedVisitor = {};
  closeIdes = [];
  sendButtonClickedIdes = [];
  userRole = "agent"; //// Owner, Agent, Admin
  firstRespChatArray = [];
  selectionStart = 0;
  selectionEnd = 0;
  isEditorLoaded = false;
  repeatFun = true;
  caretPositionObj = {};
  savedRange = -1;
  mainInputBeforeCursor = "";
  predictionFlag = "Incomplete";
  cannedTextDetectedFlag = false;
  previousSearchText = (Math.random() + 1).toString(36).substring(2);
  predictedTerm = "";
  // shiftEnterFlag = false;
  typingTimer = 0;
  noCannedFoundFlag = false;
  togglePrediction = 1; // 1 for OpenAI API & 0 for inbuilt search
  userTypingFlag = false;
  currentCursorPos = 0;
  cannedTextInfoFlag = "noCannedText";

  // To use global data pass second parameter context in constructor
  constructor(props, context) {
    super(props);
    var offset = new Date().getTimezoneOffset() * 60000;
    this.canCalling = isCallingEnable(this.agent);
    this.settings = {
      dots: false,
      infinite: true,
      speed: 0,
      slidesToShow: context.slidesToShow,
      slidesToScroll: 1,
      focusOnSelect: true,
    };
    this.state = {
      offset: offset,
      startBtnText: "",
      isTyping: false,
      chatPickStatusTransaction: context.chatPickStatusTransaction,
      sProcessing: false,
      filesToBeSentChats: [],
      printcount: 10,
      filesToBeSent: [],
      filesPreviewChats: [],
      filesPreview: [],
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      idle_time: 5,
      settings: {},
      chatMessages: {},
      groupChatAgents: [],
      incoming_picked_email: false,
      chatTypes: "chat",
      liveChatDocId2: "",
      clientPlan: false,
      // showDeskTicketPopUp: false,
      whatsAppTemplates: [],
      whatsAppTemplatesSelected: {},
      whatsAppTemplatesPreviewText:
        "Please Select a template to preview message.",
      /* For canned responses start here */
      showCannedResp: false,
      cannedList: [],
      showAddCanned: false,
      cannedText: "",
      cannedHashTag: "",
      cannedApplicableOn: 0,
      searchTextLength: 0,
      searchText: "",
      currentChildNode: "",
      predictionTypeValue: "Click for OpenAI Prediction",
      doneTypingInterval: 100,

      /* For Smart Compose Start */
      predictableContainerId: `${generateRandomId()}-${generateRandomId()}`,
      editorId: `${generateRandomId()}-${generateRandomId()}`,
      mediumEditor: null,
      font: "Arial, serif!important",
      fontSize: "16.63px!important",
      letterSpacing: "0!important",
      current_browser: getDeviceBrowser(),
      /* For Smart Compose End */
    };
    // this.saveAlgoliaData();
    //this.getSuggestions("load", false);
    this.getSettings();
    this.getEmailPreferences();
    this.keyPressed = "";
    this.pasteData = this.pasteData.bind(this);
  }

  getSettings = () => {
    firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("settings")
      .doc("IDLETIME")
      .get()
      .then((result) => {
        if (result.exists) {
          var setting = result.data();
          setting.agent_inactive_min = parseInt(setting.agent_inactive_min);
          setting.agent_inactive_status = parseInt(
            setting.agent_inactive_status
          );
          setting.agent_reply_min = parseInt(setting.agent_reply_min);
          this.setState({ settings: setting });
        }
      });
  };

  getEmailPreferences = () => {
    firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("settings")
      .doc("EMAILPREFERENCE")
      .get()
      .then((docs) => {
        if (docs.exists) {
          let _data = docs.data();
          this.setState({
            incoming_picked_email:
              _data.incoming_picked_email === true ? true : false,
          });
        }
      });
  };

  lookupSuggestions = (currentText, suggestion) => {
    if (suggestion.startsWith(currentText)) {
      return suggestion;
    }
    return false;
  };

  getMatchingSuggestion = (queryValue) => {
    return new Promise((resolve) => {
      const allSuggestions = [];
      let tmpQueryValue = queryValue ? queryValue.toLowerCase() : "";
      words.filter((suggestion, index) => {
        let tmpSuggestion = suggestion.toLowerCase();
        //const match = this.lookupSuggestions(queryValue, suggestion);
        const match = this.lookupSuggestions(tmpQueryValue, tmpSuggestion);
        if (match) {
          //let sugtion = suggestion.replace(queryValue, "");
          let sugtion = match.replace(tmpQueryValue, "");
          allSuggestions.push({
            index,
            match: sugtion,
            value: sugtion,
          });
        }
      });
      const firstSuggestion = allSuggestions.slice(0, 1);
      return resolve({
        matches: allSuggestions.length,
        list: firstSuggestion,
      });
    });
  };

  doComponentMount = () => {
    return false;
  };

  /**
   * Renders an invisible `shadowNode` DOM element, positioned at the beginning of the last word.
   *
   * From here, `getBoundingClientRect` is called to obtain the top and left coordinates of the
   * word, and are used to overlay the suggested word container in exactly the same position.
   */

  getLastWordCoordinates = () => {
    try {
      //if (this.state.current_browser !== "Safari" && this.state.current_browser !== "Mozilla") {
      if (this.state.current_browser !== "Safari") {
        const selection = window.getSelection(),
          anchorNode = selection.anchorNode,
          lastWordOffset = this.getLastWordOffset(this.getLastWord()),
          range = document.createRange();
        range.setStart(anchorNode, lastWordOffset);
        range.setEnd(anchorNode, lastWordOffset);
        range.collapse(false);
        // Create a `shadowNode`, which is an invisible DOM node that allows the coordinates of the following
        // word to be calculated.
        const shadowNode = document.createElement("span");
        shadowNode.id = generateRandomId();
        shadowNode.appendChild(document.createTextNode(""));
        range.insertNode(shadowNode);
        let parentNode = shadowNode.parentElement;
        // Rejoins the text nodes which have been split by inserting the shadow node.
        parentNode.normalize();
        parentNode.removeChild(shadowNode);
      }
    } catch (err) {}
  };

  getLastWord = () => {
    const selectedElement = window.getSelection().anchorNode;
    if (
      this.state.current_browser === "Safari" ||
      this.state.current_browser === "Mozilla"
    ) {
      if (
        selectedElement &&
        selectedElement.data &&
        selectedElement.data.length > 5
      ) {
        let tmpDt = selectedElement.data.slice(-5);
        let spltDt = tmpDt.split(" ");
        return spltDt.length > 1 ? spltDt[1].trim() : tmpDt;
      }
    } else {
      if (selectedElement && selectedElement.data) return selectedElement.data;
    }
  };

  getLastWordOffset = (text) => {
    if (text) {
      const words = text.split(" ");
      const range = this.getLastWord();
      const lastWordOfRange = words[words.length - 1].trim();
      // Returns the offset position of the last word.
      return range.length - lastWordOfRange.length;
    }
    return 0;
  };

  insertTextAtCursor = (editor, text) => {
    /* Insert span text in div start */
    let selection, range;
    if (window.getSelection) {
      selection = window.getSelection();
      if (selection && selection.getRangeAt && selection.rangeCount) {
        range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(text));
      }
    }
    /* Insert span text in div end */
    // TODO: Fix issue with Firefox pushing caret to new paragraph
    this.placeCaretAtEnd(editor);
  };

  placeCaretAtEnd = (editor) => {
    /// Working fine in Chrome
    if (
      typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined"
    ) {
      const range = document.createRange();
      range.selectNodeContents(editor);
      range.collapse(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  // * OPENAI API CALLED added by Ravi Sane
  findPredicted = () => {
    var mainInput = document.getElementById("msg");

    if (this.userTypingFlag === false) {
      let tempMainInputInnerText = mainInput.innerText.replace(/\n/g, " ");

      // * Logic to find phrases after the end of sentence.... Note: ?!. are considered as end of sentence
      let indexList = [
        ...tempMainInputInnerText.matchAll(new RegExp("[.!?]", "gi")),
      ].map((a) => a.index); // to find the end of sentence
      var enteredVal = "";
      if (indexList.length > 0) {
        enteredVal = tempMainInputInnerText
          .slice(
            indexList[indexList.length - 1] + 1,
            tempMainInputInnerText.length
          )
          .trimStart(); // * To get Phrase of words
      } else {
        enteredVal = tempMainInputInnerText.trimStart();
      }

      // console.log(`Complete sentence as enteredVal: "${enteredVal}"`);
      if (enteredVal.trim() === "" || enteredVal.includes("/")) {
        // if no entered words found ---->> No Prediction
        console.log("Nothing entered... No prediction found");
        this.predictionFlag = "Incomplete";
        return;
      }
      // console.log("💡🛠 -> file: footer.js -> line 652 -> enteredVal", enteredVal);
      //* API CALLING CODE
      var body = [`ownerId=${this.agent.ownerId}&search_word=${enteredVal}`];
      //fetch(MAIL_FUNCTION_URL + "/smartcomposeopenapi/completions", {
      fetch(GATEWAY_URL + "/completions", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((response) => response.json())
        .then((jsondata) => {
          let tempMainInputInnerTextTemp = mainInput.innerText.replace(
            /\n/g,
            " "
          ); // this is to again find the status of the current text
          // * Logic to find phrases after the end of sentence.... Note: ?!. are considered as end of sentence
          let indexListTemp = [
            ...tempMainInputInnerTextTemp.matchAll(new RegExp("[.!?]", "gi")),
          ].map((a) => a.index); // to find the end of sentence
          let enteredValTemp = "";
          if (indexListTemp.length > 0) {
            enteredValTemp = tempMainInputInnerTextTemp
              .slice(
                indexListTemp[indexListTemp.length - 1] + 1,
                tempMainInputInnerTextTemp.length
              )
              .trimStart(); // * To get Phrase of words
          } else {
            enteredValTemp = tempMainInputInnerTextTemp.trimStart();
          }
          // console.log(jsondata); // JSON data parsed by `data.json()` call
          // logic to get end of sentence in predicted result
          let textPredicted = jsondata["choices"][0]["text"]; // str has complete result
          let textSent = jsondata["search_word"];
          let indexList = [
            ...textPredicted.matchAll(new RegExp("[.!?]", "gi")),
          ].map((a) => a.index); // to find the end of sentence
          if (
            // textSent === enteredVal
            enteredValTemp === textSent
          ) {
            // to avoid forget old response in case multiple api calls
            // condition to either show till complete sentence, or whole predicted phrases
            if (indexList.length > 0) {
              this.predictedTerm = textPredicted
                .slice(0, indexList[0] + 1)
                .trim();
            } else {
              this.predictedTerm = textPredicted.trim();
            }
          }
          // this.predictedTerm = findPhrase.slice(enteredVal.length,).trim();
          console.log(
            `The Prediction from SmartCompose-->>"${this.predictedTerm}"`
          );
        })
        .then(() => {
          if (this.predictedTerm.length && this.userTypingFlag === false) {
            this.predictionFlag = "Complete";
            let mainInput = document.getElementById("msg");
            var node = document.createElement("span");
            node.contentEditable = false;
            node.suppressContentEditableWarning = true;
            node.innerHTML =
              '<span id="predictedTermId" style="color: gray"></span>';
            mainInput.appendChild(node);
            document.getElementById("predictedTermId").innerText =
              this.predictedTerm;
          } else {
            this.predictedTerm = "";
            this.predictionFlag = "Incomplete";
          }
          return;
        })
        .catch(function (jsondata, textStatus, jqXHR) {
          console.log(jsondata);
        });
    }
  };

  // * local search for auto completion added by Ravi Sane NO ML USED, Only search and match
  findPredicted2 = () => {
    // * find prediction based on last set of words
    const upperCasedWords = words.map((name) => name.toUpperCase());

    var mainInput = document.getElementById("msg");

    let tempMainInputInnerText = mainInput.innerText.replace(/\n/g, " ");

    // * Logic to find phrases after the end of sentence.... Note: ?!. are considered as end of sentence
    let indexList = [
      ...tempMainInputInnerText.matchAll(new RegExp("[.!?]", "gi")),
    ].map((a) => a.index); // to find the end of sentence
    var enteredVal = "";
    if (indexList.length > 0) {
      enteredVal = tempMainInputInnerText
        .slice(
          indexList[indexList.length - 1] + 1,
          tempMainInputInnerText.length
        )
        .trim(); // * To get Phrase of words
    } else {
      enteredVal = tempMainInputInnerText.trim();
    }

    // console.log(`Complete sentence as enteredVal: "${enteredVal}"`);
    if (enteredVal.trim() === "") {
      // if no entered words found ---->> No Prediction
      console.log("Nothing entered... No prediction found");
      this.predictionFlag = "Incomplete";
      return;
    }
    //* Loop to search text in the given list of Words, by one by one deleting the first word
    let nbsp = String.fromCharCode(160);
    var tempEnteredVal = enteredVal.replace(nbsp, " ").replace(/\s\s+/g, " "); // to keep predicting if &nbsp; is present in enteredVal
    var findPhrase = "";
    for (let j = 0; j < enteredVal.split(" ").length; j++) {
      let matchIndex = upperCasedWords.findIndex((s) =>
        s.startsWith(tempEnteredVal.toUpperCase() + " ")
      ); // search text in list of words
      findPhrase = words[matchIndex];
      if (findPhrase === "" || findPhrase === undefined) {
        tempEnteredVal = tempEnteredVal.substr(tempEnteredVal.indexOf(" ") + 1);
        findPhrase = "";
        continue;
      } else {
        break;
      }
    }

    this.predictedTerm = findPhrase.slice(tempEnteredVal.length).trim();
    console.log(`The Prediction from SmartCompose-->>"${this.predictedTerm}"`);

    if (this.predictedTerm.length) {
      this.predictionFlag = "Complete";
      let mainInput = document.getElementById("msg");
      var node = document.createElement("span");
      node.contentEditable = false;
      node.suppressContentEditableWarning = true;
      node.innerHTML = '<span id="predictedTermId" style="color: gray"></span>';
      mainInput.appendChild(node);
      document.getElementById("predictedTermId").innerText = this.predictedTerm;
    } else {
      this.predictionFlag = "Incomplete";
      console.log("No prediction found");
    }
    return;
  };

  pressTabKey = () => {
    // * the suggestion is Accepted and Prediction flag is reset to Incomplete
    this.predictionFlag = "Incomplete";
    let mainInput = document.getElementById("msg");
    let nodes = mainInput.childNodes;
    // console.log("💡🛠 || file: footer.js || line 869 || nodes", nodes);
    mainInput.removeChild(nodes[nodes.length - 2]);
    if (
      this.mainInputBeforeCursor[this.mainInputBeforeCursor.length - 1] === " "
    )
      mainInput.innerHTML =
        this.mainInputBeforeCursor.replace(/\n/g, "<br>") +
        " " +
        this.predictedTerm;
    else
      mainInput.innerHTML =
        this.mainInputBeforeCursor.replace(/\n/g, "<br>") + this.predictedTerm;
    this.setCaretPosition(nodes[nodes.length - 1].length, nodes.length - 1); // go to last position of last node
    this.resetSmartCompose(mainInput);
    this.currentCursorPos = this.getCaretPositionCanned();
  };

  /* For Smart Compose End */

  /* For Social media send image/video Start */

  convertInToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  startThirdPartyUploadImage = (filesToBeSent, chatID, chatSource) => {
    var counterImage = 0;
    filesToBeSent.forEach((filesdata) => {
      counterImage = counterImage + 1;
      this.uploadThirdPartyImage(filesdata, counterImage, chatID, chatSource);
    });
  };

  /* final upload images one by one */
  async uploadThirdPartyImage(file, counterImage, chatID, chatSource) {
    var filesize = 0;
    var folder = getChatFileFolder();
    let fileNameData = file.name;
    let name =
      folder +
      "/" +
      this.agent.ownerIdentifire +
      "_" +
      this.agent.agent_short +
      "_" +
      +new Date() +
      "_" +
      Math.ceil(Math.random() * 999999) +
      "_" +
      file.name;
    var contentType = "";
    let metadata = {
      contentType: file.type,
    };
    contentType = file.type;
    filesize = file.size;
    let filesPreviewChats = this.state.filesPreviewChats;
    var filesPreview = this.state.filesPreview;
    if (chatID === this.context.liveChatDocId) {
      try {
        document
          .getElementById("uploading_" + counterImage + "_" + chatID)
          .classList.add("uploading");
        document.getElementById(
          "delete_" + counterImage + "_" + chatID
        ).style.display = "none";
      } catch (error) {}
    }

    let task = firebaseServices.storageConnection
      .child(this.agent.ownerIdentifire + "/chat_files/" + name)
      .put(file, metadata);
    await task
      .then((snapshot) => snapshot.ref.getDownloadURL())
      .then((url) => {
        var msgContent;
        var msg_url;
        var msgtype = 1;
        var S = file.type;

        if (S.includes("image")) {
          msgContent = url;
          msg_url = fileNameData;
          if (chatSource === "twitter") {
            msgtype = 22;
          } else if (chatSource === "whatsapp") {
            msgtype = 71;
          } else if (chatSource === "instagram") {
            msgtype = 42;
          }
        } else if (S.includes("application")) {
          msgContent = url;
          msgtype = 3;
          msg_url = fileNameData;
          if (chatSource === "whatsapp") {
            msgtype = 74;
          }
        } else if (S.includes("video")) {
          msgContent = url;
          msg_url = fileNameData;
          if (chatSource === "twitter") {
            msgtype = 23;
          } else if (chatSource === "whatsapp") {
            msgtype = 73;
          } else if (chatSource === "instagram") {
            msgtype = 43;
          }
        } else {
          msgContent = url;
          msg_url = fileNameData;
          msgtype = 3;
        }

        this.saveThirdPartyMessage(
          file,
          msgContent,
          msgtype,
          msg_url,
          contentType,
          chatID,
          filesize,
          "addmessage",
          chatSource
        );

        filesPreview[counterImage - 1] = "";
        let tmpFilesPreview = filesPreview.filter((data222) => {
          return data222 === "" ? false : true;
        });
        filesPreviewChats[chatID] = tmpFilesPreview;
        var notempty = this.checkemptyData(tmpFilesPreview);
        if (notempty === false) {
          filesPreviewChats[chatID] = [];
          this.setState({ filesPreview: [], filesPreviewChats });
          if (chatID === this.context.liveChatDocId) {
            var btnClicked = this.sendButtonClickedIdes.indexOf(chatID);
            if (btnClicked !== -1) {
              this.sendButtonClickedIdes.splice(btnClicked, 1);
            }
            document.getElementById("fileUploader").value = ""; // Empty input field
            document.getElementById("App").style.display = "none";
            document.getElementById("DragShowHide").style.display = "none";
          }
        } else {
          this.setState({ filesPreview: tmpFilesPreview, filesPreviewChats });
        }
      })
      .catch((error) => {});
  }

  saveThirdPartyMessage = async (
    file,
    msgContent,
    msg_type,
    msg_url,
    contentType,
    chatId,
    filesize,
    actionType,
    chatSource
  ) => {
    var agentProfile = encodeURIComponent(this.agent.profile_img);
    var tmpmsgContent = encodeURIComponent(msgContent);
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};
    var chatType = tmpCurVisitor.start_url ? tmpCurVisitor.start_url : "";
    var agent_start = tmpCurVisitor.agent_start
      ? tmpCurVisitor.agent_start
      : "";
    var agent_ids = tmpCurVisitor.agent_ids ? tmpCurVisitor.agent_ids : [];
    if (chatType === "voip_call") {
      chatType = "voip";
    } else {
      chatType = "chat";
    }

    let userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    agentProfile = userDetails.profile_img
      ? encodeURIComponent(userDetails.profile_img)
      : "";
    ///////////////// Starting sending message start //////////////////
    var body = [
      `ownerId=${this.agent.ownerId}&chatId=${chatId}&agentId=${
        this.agent.agentId
      }&agentName=${
        this.agent.name
      }&agentProfile=${agentProfile}&agentShort=${nameIntials(
        this.agent.name
      )}&msg=${tmpmsgContent}&msg_type=${msg_type}&msg_url=${msg_url}&contenttype=${contentType}&filesize=${filesize}&action=${actionType}&chatType=${chatType}&agent_start=${agent_start}&agents=${JSON.stringify(
        agent_ids
      )}`,
    ];
    fetch(GATEWAY_URL + "/saveMessage", {
      method: "post",
      body: body,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        const status = response.result ? response.result : "";
        if (status === "success") {
          let chatData = {
            msgId: response.chatId,
            ownerId: this.agent.ownerId,
            chatId: this.context.liveChatDocId,
            message: tmpmsgContent,
            agentId: this.agent.agentId,
            msg_type: msg_type,
          };

          /* For whatsapp start */
          if (chatSource === "whatsapp") {
            let serverTimestamp22 = await getDateInUTC();
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatData.chatId)
              .update({
                updatedon: serverTimestamp22,
                latest_msg: "Attachment",
              });
            // Whatsapp Message code starts through twilio
            sendWhatsAppMessage(this.context.currentVisitor, chatData);
          }
          /* For whatsapp End */

          /* For Twitter message start */
          if (
            chatSource === "twitter" &&
            this.agent.tdm_tokens &&
            Object.keys(this.agent.tdm_tokens).length > 0
          ) {
            if (msg_type === 22) {
              // Image
            } else if (msg_type === 23) {
              // Video
            }

            const media_file = await this.convertInToBase64(file);
            let body22 = [
              `recipient_id=${
                this.context.currentVisitor.recipientId
              }&sender_id=${
                this.context.currentVisitor.senderId
              }&message=${tmpmsgContent}&msgId=${encodeURIComponent(
                chatData.msgId
              )}&ownerId=${encodeURIComponent(
                chatData.ownerId
              )}&chatId=${encodeURIComponent(
                chatData.chatId
              )}&agentId=${encodeURIComponent(
                chatData.agentId
              )}&consumer_key=${encodeURIComponent(
                this.agent.tdm_tokens.consumer_key
              )}&consumer_secret=${encodeURIComponent(
                this.agent.tdm_tokens.consumer_secret
              )}&token=${encodeURIComponent(
                this.agent.tdm_tokens.access_token
              )}&token_secret=${encodeURIComponent(
                this.agent.tdm_tokens.access_secret
              )}&media_file=${encodeURIComponent(
                media_file
              )}&msg_type=${msg_type}&filesize=${filesize}&contenttype=${contentType}`,
            ];
            //fetch(MAIL_FUNCTION_URL + "/twitter/sendmessage", {
            fetch(GATEWAY_URL + "/tw_sendmessage", {
              method: "POST",
              body: body22,
              headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Origin: window.location.origin,
              },
            })
              .then((res22) => res22.json())
              .then(async (response) => {});
          }
          /* For Twitter message end */

          /* For Instagram start */
          if (chatSource === "instagram") {
            let serverTimestamp22 = await getDateInUTC();
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatData.chatId)
              .update({
                updatedon: serverTimestamp22,
                latest_msg: "Attachment",
              });

            let changeTextLangArr = this.context.changeTextLangArr
              ? this.context.changeTextLangArr
              : {};
            let lang_code = "";
            if (changeTextLangArr.hasOwnProperty(chatId)) {
              lang_code = changeTextLangArr[chatId];
            }

            const agentFromStorage = localStorage.getItem("agent")
              ? JSON.parse(localStorage.getItem("agent"))
              : {};
            let pageToken =
              agentFromStorage.insta_pages_token[
                this.context.currentVisitor.recipientId
              ];
            let body22 = [
              `recipient_id=${
                this.context.currentVisitor.recipientId
              }&sender_id=${
                this.context.currentVisitor.senderId
              }&message=${tmpmsgContent}&msgId=${encodeURIComponent(
                chatData.msgId
              )}&ownerId=${encodeURIComponent(
                chatData.ownerId
              )}&chatId=${encodeURIComponent(
                chatData.chatId
              )}&agentId=${encodeURIComponent(
                chatData.agentId
              )}&page_token=${encodeURIComponent(
                pageToken
              )}&lang_code=${lang_code}&start_url=${
                this.context.currentVisitor.start_url
              }&msg_type=${msg_type}&filesize=${filesize}&contenttype=${contentType}`,
            ];

            //fetch(`${MAIL_FUNCTION_URL}/facebook/ig_sendmessage`, {
            fetch(`${GATEWAY_URL}/ig_sendmessage`, {
              method: "POST",
              body: body22,
              headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Origin: window.location.origin,
              },
            })
              .then((res) => res.json())
              .then(async (response) => {});
          }
          /* For Instagram End */

          /* Check first message time start */
          if (this.firstRespChatArray.indexOf(chatId) === -1) {
            this.firstRespChatArray.push(chatId);
            let datObj = this.agent;
            datObj.currentVisitor = tmpCurVisitor;
            updateAgentChatData(datObj, chatId, "first_msg_response");
          }
          /* Check first message time end */
        }
      })
      .catch((err) => {});

    ///////////////// Starting sending message end //////////////////

    var docRef = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("visitors")
      .doc(chatId);
    docRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          var visitorData = doc.data();
          const chatagentIds = visitorData.agent_ids;
          const leaveAgentsIds = visitorData.agent_leave;
          const increment = firebase1.firestore.FieldValue.increment(1);
          var agentReference = firebaseServices.db
            .collection("users")
            .doc(this.agent.ownerId)
            .collection("agents")
            .doc(this.agent.agentId);

          var content = msgContent;
          if (msg_type === 1) {
            content = msgContent;
          } else if (msg_type === 2) {
            content = "Image";
          } else if (msg_type === 4) {
            content = "Video";
          } else if (msg_type === 6) {
            content = msgContent;
          } else if (msg_type === 7) {
            content = msgContent;
          } else {
            content = "File";
          }
          var includeClientId = chatagentIds.includes(this.agent.agentId);
          this.readCount(chatId);
          var serverTimestamp = await getDateInUTC();

          //// Update Last Activity Start ///////
          updateAgentLastActivityTime(); // add last activity time on each page when agent is Visit
          //// Update Last Activity End  ///////

          if (
            includeClientId === false &&
            leaveAgentsIds.indexOf(this.agent.agentId) === -1
          ) {
            chatagentIds.push(this.agent.agentId);
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatId)
              .collection("agent_data")
              .doc(this.agent.agentId)
              .set({
                addedon: new Date().getTime() + this.state.offset,
                agent_added_by: this.agent.agentId,
                agent_added_by_name: this.agent.name,
                agent_id: this.agent.agentId,
                agent_name: this.agent.name,
                agent_profile_img: this.agent.profile_img,
                status: 1,
                agent_ref: agentReference,
                calling_status: this.agent.calling_status
                  ? this.agent.calling_status
                  : 0,
              })
              .then((ref) => {})
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
            docRef.update({
              agent_ids: chatagentIds,
              updatedon: serverTimestamp,
              latest_msg: content,
            });
            agentReference.update({ chat_total: increment });
          } else {
            docRef.update({ updatedon: serverTimestamp, latest_msg: content });
          }
          //////////// End //////////////////////////////
        }
      })
      .catch(function (error) {
        console.log("Error saving document:", error);
      });
  };

  /* For Social media send image/video End */

  /* Twitter DM functions start */

  checkTwitterDMEnter = (event) => {
    if (!this.checkResizeRunTwitterDM) {
      this.checkResizeRunTwitterDM = true;
      autosize(event.target);
    }
    if (event.target.value.trim() !== "") {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.target.style.height = "46px";
          event.preventDefault();
          this.sendTwitterDMMessage();
          return;
        } else {
          autosize(event.target);
        }
      }
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    }
  };

  sendTwitterDMMessage = async () => {
    let isContinue = true;
    if (this.context.goneOffline) {
      isContinue = false;
    }
    if (Object.keys(this.context.currentVisitor).length > 0) {
      let agent_end = this.context.currentVisitor.agent_end
        ? this.context.currentVisitor.agent_end
        : "";
      let isChatend = this.context.currentVisitor.isChatend
        ? this.context.currentVisitor.isChatend
        : 0;
      if (agent_end === "VISITOR_END_CHAT" || isChatend === 1) {
        isContinue = false;
      }
    }

    if (!isContinue) {
      return false;
    }

    var message = document.getElementById("txtTwitterDM").value;
    message = message.trim();
    let chatId = this.context.liveChatDocId;
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};

    /* For attachment start */

    // For Draft massages Start //////
    try {
      let tmpMsgObj = this.state.chatMessages ? this.state.chatMessages : {};
      if (tmpMsgObj.hasOwnProperty(chatId)) {
        delete tmpMsgObj[chatId];
        this.setState({ chatMessages: tmpMsgObj });
      }
    } catch (err) {}
    // For Draft massages End //////

    /* let filesToBeSentChats = this.state.filesToBeSentChats;
		if (filesToBeSentChats[chatId]) {
			if (filesToBeSentChats[chatId].length > 0) {
				if(this.sendButtonClickedIdes.indexOf(chatId) === -1) {
					this.sendButtonClickedIdes.push(chatId);
				}
				this.context.updateGlobalData("isDisableIncomingPanel", true);
				this.startThirdPartyUploadImage(filesToBeSentChats[chatId], chatId, "twitter");
				filesToBeSentChats[chatId] = [];
				this.setState({ filesToBeSentChats }, () => {
					setTimeout(() => {
						this.context.updateGlobalData("isDisableIncomingPanel", false);
					}, 500);
				});
			}
		} */

    this.closeEmojis();
    /* For attachment end */

    try {
      document.getElementById("txtTwitterDM").value = "";
      document.getElementById("txtTwitterDM").style.height = "46px";
    } catch (err) {}

    if (message) {
      var agentProfile = encodeURIComponent(this.agent.profile_img);
      var tmpmsgContent = encodeURIComponent(message);
      var body = [
        `ownerId=${this.agent.ownerId}&chatId=${
          this.context.liveChatDocId
        }&agentId=${this.agent.agentId}&agentName=${
          this.agent.name
        }&agentProfile=${agentProfile}&agentShort=${nameIntials(
          this.agent.name
        )}&msg=${tmpmsgContent}&msg_type=21&msg_url=&contenttype=&filesize=0&action=addmessage`,
      ];
      fetch(GATEWAY_URL + "/saveMessage", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.result === "success") {
            let chatData = {
              msgId: response.chatId,
              ownerId: this.agent.ownerId,
              chatId: this.context.liveChatDocId,
              message: tmpmsgContent,
              agentId: this.agent.agentId,
            };

            let serverTimestamp22 = await getDateInUTC();
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatData.chatId)
              .update({ updatedon: serverTimestamp22, latest_msg: message });
            if (
              this.agent.tdm_tokens &&
              Object.keys(this.agent.tdm_tokens).length > 0
            ) {
              let changeTextLangArr = this.context.changeTextLangArr
                ? this.context.changeTextLangArr
                : {};
              let lang_code = "";
              if (changeTextLangArr.hasOwnProperty(chatId)) {
                lang_code = changeTextLangArr[chatId];
              }

              let body22 = [
                `recipient_id=${
                  this.context.currentVisitor.recipientId
                }&sender_id=${
                  this.context.currentVisitor.senderId
                }&message=${tmpmsgContent}&msgId=${encodeURIComponent(
                  chatData.msgId
                )}&ownerId=${encodeURIComponent(
                  chatData.ownerId
                )}&chatId=${encodeURIComponent(
                  chatData.chatId
                )}&agentId=${encodeURIComponent(
                  chatData.agentId
                )}&consumer_key=${encodeURIComponent(
                  this.agent.tdm_tokens.consumer_key
                )}&consumer_secret=${encodeURIComponent(
                  this.agent.tdm_tokens.consumer_secret
                )}&token=${encodeURIComponent(
                  this.agent.tdm_tokens.access_token
                )}&token_secret=${encodeURIComponent(
                  this.agent.tdm_tokens.access_secret
                )}&lang_code=${lang_code}`,
              ];
              fetch(GATEWAY_URL + "/tw_sendmessage", {
                method: "POST",
                body: body22,
                headers: {
                  "X-API-Key": FUNCTION_API_KEY,
                  "Content-Type": "application/x-www-form-urlencoded",
                  Accept: "application/json",
                  Origin: window.location.origin,
                },
              })
                .then((res) => res.json())
                .then(async (response) => {});
            }

            /* Check first message time start */
            if (this.firstRespChatArray.indexOf(chatId) === -1) {
              this.firstRespChatArray.push(chatId);
              let datObj = this.agent;
              datObj.currentVisitor = tmpCurVisitor;
              updateAgentChatData(datObj, chatId, "first_msg_response");
            }
            /* Check first message time end */
          }
        })
        .catch((err) => {});
    }
  };

  /* Twitter DM functions end */

  /* Facebook messanger functions start */

  checkMessangerEnter = (event) => {
    if (!this.checkResizeRunMessanger) {
      this.checkResizeRunMessanger = true;
      autosize(event.target);
    }
    if (event.target.value.trim() !== "") {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.target.style.height = "46px";
          event.preventDefault();
          this.sendMessangerMessage();
          return;
        } else {
          autosize(event.target);
        }
      }
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (this.context.currentVisitor.start_url === "incoming_insta") {
          this.sendMessangerMessage();
        }
      }
    }
  };

  sendMessangerMessage = async () => {
    var message = document.getElementById("txtMessanger").value;
    message = message.trim();

    this.closeEmojis();

    try {
      document.getElementById("txtMessanger").value = "";
      document.getElementById("txtMessanger").style.height = "46px";
    } catch (err) {}
    let chatId = this.context.liveChatDocId;
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};

    // For Draft massages Start //////
    try {
      let tmpMsgObj = this.state.chatMessages ? this.state.chatMessages : {};
      if (tmpMsgObj.hasOwnProperty(chatId)) {
        delete tmpMsgObj[chatId];
        this.setState({ chatMessages: tmpMsgObj });
      }
    } catch (err) {}
    // For Draft massages End //////

    if (this.context.currentVisitor.start_url === "incoming_insta") {
      let filesToBeSentChats = this.state.filesToBeSentChats;
      if (filesToBeSentChats[chatId]) {
        if (filesToBeSentChats[chatId].length > 0) {
          var isOK = true;
          filesToBeSentChats[chatId].forEach((filesdata) => {
            if (filesdata.size >= 8388608) {
              isOK = false;
            }
          });

          if (!isOK) {
            alert("File is too large. It can be less than 8 MB");
          } else {
            if (this.sendButtonClickedIdes.indexOf(chatId) === -1) {
              this.sendButtonClickedIdes.push(chatId);
            }
            this.context.updateGlobalData("isDisableIncomingPanel", true);
            this.startThirdPartyUploadImage(
              filesToBeSentChats[chatId],
              chatId,
              "instagram"
            );
            filesToBeSentChats[chatId] = [];
            this.setState({ filesToBeSentChats }, () => {
              setTimeout(() => {
                this.context.updateGlobalData("isDisableIncomingPanel", false);
              }, 500);
            });
          }
        }
      }
    }

    if (message) {
      let msgType =
        this.context.currentVisitor.start_url === "incoming_insta" ? 41 : 15; //this.context.currentVisitor.start_url 41 for default insta not removing now cause need confirmation
      var agentProfile = encodeURIComponent(this.agent.profile_img);
      var tmpmsgContent = encodeURIComponent(message);
      var body = [
        `ownerId=${this.agent.ownerId}&chatId=${
          this.context.liveChatDocId
        }&agentId=${this.agent.agentId}&agentName=${
          this.agent.name
        }&agentProfile=${agentProfile}&agentShort=${nameIntials(
          this.agent.name
        )}&msg=${tmpmsgContent}&msg_type=${msgType}&msg_url=&contenttype=&filesize=0&action=addmessage`,
      ];
      fetch(GATEWAY_URL + "/saveMessage", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.result === "success") {
            let chatData = {
              msgId: response.chatId,
              ownerId: this.agent.ownerId,
              chatId: this.context.liveChatDocId,
              message: tmpmsgContent,
              agentId: this.agent.agentId,
            };

            let changeTextLangArr = this.context.changeTextLangArr
              ? this.context.changeTextLangArr
              : {};
            let lang_code = "";
            if (changeTextLangArr.hasOwnProperty(chatId)) {
              lang_code = changeTextLangArr[chatId];
            }

            // if (false) {
            // 	let serverTimestamp22 = await getDateInUTC();
            // 	firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatData.chatId).update({ updatedon: serverTimestamp22, latest_msg: message });
            // 	const agentFromStorage = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
            // 	let pageToken = agentFromStorage.insta_pages_token[this.context.currentVisitor.recipientId];
            // 	let body22 = [`recipient_id=${this.context.currentVisitor.recipientId}&sender_id=${this.context.currentVisitor.senderId}&message=${tmpmsgContent}&msgId=${encodeURIComponent(chatData.msgId)}&ownerId=${encodeURIComponent(chatData.ownerId)}&chatId=${encodeURIComponent(chatData.chatId)}&agentId=${encodeURIComponent(chatData.agentId)}&page_token=${encodeURIComponent(pageToken)}&lang_code=${lang_code}&start_url=${this.context.currentVisitor.start_url}`];
            // 	//fetch(`${MAIL_FUNCTION_URL}/facebook/ig_sendmessage`, {
            // 	fetch(`${GATEWAY_URL}/ig_sendmessage`, {
            // 		method: "POST",
            // 		body: body22,
            // 		headers: {
            // 			"X-API-Key": FUNCTION_API_KEY,
            // 			"Content-Type": "application/x-www-form-urlencoded",
            // 			"Accept": "application/json",
            // 			"Origin": window.location.origin
            // 		}
            // 	}).then(res => res.json()).then(async (response) => { });

            // 	/* Check first message time start */
            // 	if (this.firstRespChatArray.indexOf(chatId) === -1) {
            // 		this.firstRespChatArray.push(chatId);
            // 		let datObj = this.agent;
            // 		datObj.currentVisitor = tmpCurVisitor;
            // 		updateAgentChatData(datObj, chatId, "first_msg_response");
            // 	}
            // 	/* Check first message time end */

            // } else {
            let serverTimestamp22 = await getDateInUTC();
            let userDetailsNew = localStorage.getItem("agent")
              ? JSON.parse(localStorage.getItem("agent"))
              : {};
            let message_type_name =
              this.context.currentVisitor.start_url === "incoming_insta"
                ? "instagram"
                : this.context.currentVisitor.start_url === "incoming_whatsapp"
                ? "whatsapp_360"
                : "facebook";
            let hookUrl = userDetailsNew.hookUrl ? userDetailsNew.hookUrl : "";
            if (hookUrl === "") {
              hookUrl = await this.getHookUrl(
                userDetailsNew.ownerId,
                message_type_name
              );
              userDetailsNew.hookUrl = hookUrl;
              localStorage.setItem("agent", JSON.stringify(userDetailsNew));
            }
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatData.chatId)
              .update({ updatedon: serverTimestamp22, latest_msg: message });

            let pageToken =
              this.agent.fb_page_token[this.context.currentVisitor.recipientId];
            let body22 = [
              `recipient_id=${
                this.context.currentVisitor.recipientId
              }&sender_id=${
                this.context.currentVisitor.senderId
              }&message=${tmpmsgContent}&msgId=${encodeURIComponent(
                chatData.msgId
              )}&ownerId=${encodeURIComponent(
                chatData.ownerId
              )}&chatId=${encodeURIComponent(
                chatData.chatId
              )}&agentId=${encodeURIComponent(
                chatData.agentId
              )}&page_token=${encodeURIComponent(
                pageToken
              )}&lang_code=${lang_code}&start_url=${
                this.context.currentVisitor.start_url
              }&hookUrl=${hookUrl}`,
            ];
            let sendMessageUrl = `${GATEWAY_URL}/sendmessage`;
            let fbHeaders = {
              "X-API-Key": FUNCTION_API_KEY,
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
              Origin: window.location.origin,
            };
            if (
              userDetailsNew &&
              (userDetailsNew.connectFacebook === true ||
                userDetailsNew.instagrambusiness_status === true ||
                userDetailsNew.instagrambusiness_status === 1 ||
                userDetailsNew.facebookmessenger_status === 1 ||
                userDetailsNew.facebookmessenger_status === true)
            ) {
              sendMessageUrl = `${GATEWAY_URL}/social_sendmessage`;
              fbHeaders = {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Origin: window.location.origin,
              };
            }
            // fetch(MAIL_FUNCTION_URL + "/connectIntegration/sendmessage", {
            fetch(sendMessageUrl, {
              method: "post",
              body: body22,
              headers: fbHeaders,
            })
              .then((res) => res.json())
              .then(async (response) => {});

            /* Check first message time start */
            if (this.firstRespChatArray.indexOf(chatId) === -1) {
              this.firstRespChatArray.push(chatId);
              let datObj = this.agent;
              datObj.currentVisitor = tmpCurVisitor;
              updateAgentChatData(datObj, chatId, "first_msg_response");
            }
            /* Check first message time end */
          }
          // }
        })
        .catch((err) => {});
    }
  };

  getHookUrl = (ownerId, eventName) => {
    return new Promise((resolve, reject) => {
      firebaseServices.db
        .collection("users")
        .doc(ownerId)
        .collection("subscribedType")
        .where("eventName", "==", eventName)
        .limit(1)
        .get()
        .then((docs) => {
          let hookUrl = "";
          if (!docs.empty) {
            docs.forEach((doc) => {
              hookUrl = doc.data().hookUrl ? doc.data().hookUrl : "";
            });
          }
          resolve(hookUrl);
        })
        .catch((err) => {
          console.log("error at line 842", JSON.stringify(err));
          resolve("");
        });
    });
  };

  /* Facebook messanger functions end */

  /* Twilio code Start */

  sendPhoneSms = async () => {
    var message = document.getElementById("txtSms").value;
    var visId = this.context.currentVisitor.id
      ? this.context.currentVisitor.id
      : "";

    try {
      document.getElementById("txtSms").value = "";
      document.getElementById("txtSms").style.height = "46px";
    } catch (err) {}

    if (visId.indexOf("TEMP") !== -1) {
      let msgData = {
        message: message,
        phoneNumber: this.context.currentVisitor.client_phone,
      };
      let cChatID2 = await sendOrCreateVisitorSMS(
        this.agent,
        msgData,
        "outside"
      );
      this.context.updateGlobalData("callChatId", cChatID2);
    } else {
      if (
        !this.context.currentVisitor.client_phone ||
        !message ||
        !this.agent.phone_number
      ) {
        console.log("Error in data!");
        return false;
      }

      /* Save SMS as message start */
      var agentProfile = encodeURIComponent(this.agent.profile_img);
      var tmpmsgContent = encodeURIComponent(message);
      var msg_url = "";
      var contentType = "";
      var actionType = "addmessage";

      var body = [
        `ownerId=${this.agent.ownerId}&chatId=${
          this.context.liveChatDocId
        }&agentId=${this.agent.agentId}&agentName=${
          this.agent.name
        }&agentProfile=${agentProfile}&agentShort=${nameIntials(
          this.agent.name
        )}&msg=${tmpmsgContent}&msg_type=11&msg_url=${msg_url}&contenttype=${contentType}&filesize=0&action=${actionType}`,
      ];
      fetch(GATEWAY_URL + "/saveMessage", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.result === "success") {
            let dataObj2 = {
              msgId: response.chatId,
              ownerId: this.agent.ownerId,
              chatId: this.context.liveChatDocId,
            };
            var sendResult = await sendSMS(
              this.agent.phone_number,
              this.context.currentVisitor.client_phone,
              message,
              dataObj2
            );
          }
        })
        .catch((err) => {});
      /* Save SMS as message End  */
    }
  };
  sendWhatsAppAgentTemplate = async (templateId, templateData) => {
    if (!this.context.currentVisitor.client_phone) {
      //|| !this.agent.whatsapp_msg)
      console.log("Error in data!");
      return false;
    }

    let chatId = this.context.liveChatDocId;
    // For Draft massages End //////

    let chatData = {
      msgId: "",
      ownerId: this.agent.ownerId,
      chatId: this.context.liveChatDocId,
      message: "",
      agentId: this.agent.agentId,
    };
    // Whatsapp Message code starts through twilio
    if (
      this.state.whatsAppTemplatesSelected &&
      this.state.whatsAppTemplatesSelected.id
    ) {
      let res = await sendWhatsAppTemplate(
        this.context.currentVisitor,
        chatData,
        this.state.whatsAppTemplatesSelected.id,
        this.agent.agent_name,
        this.state.whatsAppTemplatesSelected.content
      );
      this.context.updateGlobalData("callChatId", res.data.visitorId);
    } else {
      // select a one toast
    }

    try {
      document.getElementById("txtWhatsapp").value = "";
      document.getElementById("txtWhatsapp").style.height = "46px";
    } catch (err) {}
  };

  checkSmsEnter = (event) => {
    if (!this.checkResizeRunSMS) {
      this.checkResizeRunSMS = true;
      autosize(event.target);
    }

    if (event.target.value.trim() !== "") {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.target.style.height = "46px";
          event.preventDefault();
          this.sendPhoneSms();
          return;
        } else {
          autosize(event.target);
        }
      }
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    }
  };

  /* Twilio code End */

  /* Emoji functions start */

  closeEmojis = () => {
    try {
      document.getElementById("emojis").style.visibility = "hidden";
      document.getElementById("emojiopen").style.display = "block";
      document.getElementById("emojiclose").style.display = "none";
    } catch (error) {}
  };

  showEmojis = () => {
    try {
      document.getElementById("emojis").style.visibility = "visible";
      document.getElementById("emojiopen").style.display = "none";
      document.getElementById("emojiclose").style.display = "block";
    } catch (error) {}
  };

  emojiText = (emoji, elemId = "msg") => {
    let msg = "";
    if (elemId === "msg") {
      //* new added by Ravi Sane
      let element = document.getElementById("msg");
      try {
        document.getElementById("predictedTermId").innerText = "";
        this.predictedTerm = "";
        this.predictionFlag = "Incomplete";
        this.cannedTextDetectedFlag = false;
      } catch {}
      const msgValue = element.innerText;

      // current caret position and child node
      let index = this.currentCursorPos;
      let currentChildNodeEmoji = window.getSelection().anchorNode.data;

      // logic to get index of current child node
      var indexChild = 0;
      var children = element.childNodes;
      for (let i = 0; i < children.length; i++) {
        if (currentChildNodeEmoji === children[i].data) {
          indexChild = i;
        }
      }
      let startIndex = msgValue.search(currentChildNodeEmoji);
      element.innerText =
        msgValue.slice(0, startIndex + index) +
        emoji.native +
        msgValue.slice(startIndex + index);
      autosize(element);
      element.focus();
      try {
        if (index + emoji.native.length <= element.innerText.length) {
          this.setCaretPosition(index + emoji.native.length, indexChild);
        } else {
          this.setCaretPosition(element.innerText.length);
        }
      } catch (e) {
        console.log("Error " + e.message);
      }
      this.update(element);
      this.currentCursorPos = this.getCaretPositionCanned();
      //* new added by Ravi Sane
    } else {
      msg = document.getElementById(elemId).value;
      const text = `${msg}${emoji.native}`;
      document.getElementById(elemId).value = text;
      try {
        document.getElementById(elemId).focus();
      } catch (e) {}
    }
  };

  /* Emoji functions End */

  /* Attachment functions start */

  handleFileSelect = () => {
    this.closeEmojis();
    document.getElementById("fileUploader").click();
  };

  onDrop = (acceptedFiles, rejectedFiles, event) => {
    event.preventDefault();
    event.stopPropagation();

    document.getElementById("App").style.display = "block";
    document.getElementById("dropZoneSection").style.display = "none";

    var filesToBeSentChats = this.state.filesToBeSentChats;
    var filesToBeSent = [];
    if (filesToBeSentChats[this.context.liveChatDocId]) {
      filesToBeSent = filesToBeSentChats[this.context.liveChatDocId];
    }

    var newFilesLength = acceptedFiles.length;
    var totalLength = newFilesLength + filesToBeSent.length;

    if (totalLength <= this.state.printcount) {
      acceptedFiles.forEach((filesdata) => {
        const restrictedTypes = [
          "image/svg+xml",
          "text/javascript",
          "text/html",
          "",
        ];
        if (restrictedTypes.includes(filesdata.type)) {
          toast.error(`Unable to handle file type: ${filesdata.type}`);
          return;
        }
        filesToBeSent.push(filesdata);
      });

      if (filesToBeSent.length <= this.state.printcount) {
        filesToBeSentChats[this.context.liveChatDocId] = filesToBeSent;
        this.setState({ filesToBeSent, filesToBeSentChats }, () =>
          this.fileCount(
            filesToBeSentChats[this.context.liveChatDocId],
            this.context.liveChatDocId
          )
        );
      } else {
        alert("You have reached the limit of uploading files at a time.....");
        document.getElementById("App").style.display = "none";
      }
    } else {
      alert("You have reached the limit of uploading files at a time..!!!");
      document.getElementById("App").style.display = "none";
    }
  };

  /* count number of file */

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  fileCount = (filesToBeSent, chatID) => {
    var filesPreview = [];
    const filesPreviewChats = this.state.filesPreviewChats;

    var i = 1;
    var k = 0;
    //if (chatID === this.context.liveChatDocId) {
    filesToBeSent.forEach((filesdata) => {
      var ids = "file_" + i;
      var deleteid = "delete_" + k + "_" + chatID;
      var deleteNode = "delete_" + i + "_" + chatID;
      var uploadNode = "uploading_" + i + "_" + chatID;
      let forSize = this.formatBytes(filesdata.size, 2);
      filesPreview.push(
        <div key={ids} id={ids} className="file-attched-complete-drop">
          <span id={uploadNode}>&nbsp;</span>
          <span className="iconNameDropFile">
            <img
              alt=""
              src={require("../../../../assets/img/icon/blank-file.png")}
              className="iconNameDropFileImg"
            />
          </span>
          <span className="fileSendAttchedName">
            <span className="fileAttchedOnlyName">{filesdata.name}</span>
          </span>
          <span className="fileSendAttchedSize">{forSize}</span>
          <a
            onClick={() => this.deleteFiles(deleteid)}
            id={deleteNode}
            className="delete-drag-file"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.006"
              height="13.006"
              viewBox="0 0 13.006 13.006"
            >
              <g
                id="Group_2683"
                data-name="Group 2683"
                transform="translate(-4.877 6.503) rotate(-45)"
              >
                <rect
                  id="Rectangle_1271"
                  data-name="Rectangle 1271"
                  width="2.299"
                  height="16.094"
                  rx="1.15"
                  transform="translate(6.898 0)"
                  fill="#fff"
                />
                <rect
                  id="Rectangle_1272"
                  data-name="Rectangle 1272"
                  width="2.299"
                  height="16.094"
                  rx="1.15"
                  transform="translate(16.094 6.898) rotate(90)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </div>
      );
      i++;
      k++;
    });

    filesPreviewChats[chatID] = filesPreview;
    this.setState({ filesPreview, filesPreviewChats });

    if (filesPreview.length === 0) {
      document.getElementById("fileUploader").value = ""; // Empty input field
      document.getElementById("App").style.display = "none";
      document.getElementById("DragShowHide").style.display = "none";
    } else {
      document.getElementById("App").style.display = "block";
      document.getElementById("DragShowHide").style.display = "block";
    }
    //}
  };

  /* delete files when delete icon click in file upload */
  deleteFiles = (deleteFiles) => {
    var fileData = deleteFiles.split("_");
    var chatID = fileData[2];
    var arraypointer = fileData[1];
    let filesToBeSentChats = this.state.filesToBeSentChats;
    let filesToBeSent = filesToBeSentChats[chatID];
    filesToBeSent.splice(arraypointer, 1);
    filesToBeSentChats[chatID] = filesToBeSent;

    this.setState({ filesToBeSentChats }, () => {
      this.fileCount(filesToBeSent, chatID);
    });
  };

  /* start uploading image one by one*/
  startUploadImage = (filesToBeSent, chatID) => {
    var counterImage = 0;
    filesToBeSent.forEach((filesdata) => {
      counterImage = counterImage + 1;
      this.uploadImage(filesdata, counterImage, chatID);
    });
  };

  /* final upload images one by one */
  async uploadImage(file, counterImage, chatID) {
    var filesize = 0;
    var folder = getChatFileFolder();
    let fileNameData = file.name;
    let name =
      folder +
      "/" +
      this.agent.ownerIdentifire +
      "_" +
      this.agent.agent_short +
      "_" +
      +new Date() +
      "_" +
      Math.ceil(Math.random() * 999999) +
      "_" +
      file.name;
    var contentType = "";
    let metadata = {
      contentType: file.type,
    };
    contentType = file.type;
    filesize = file.size;
    let filesPreviewChats = this.state.filesPreviewChats;
    var filesPreview = this.state.filesPreview;

    if (chatID === this.context.liveChatDocId) {
      try {
        document
          .getElementById("uploading_" + counterImage + "_" + chatID)
          .classList.add("uploading");
        document.getElementById(
          "delete_" + counterImage + "_" + chatID
        ).style.display = "none";
      } catch (error) {}
    }
    this.uploadFile(file, folder)

      .then((url) => {
        var msgContent;
        var msg_url;
        var msgtype = 1;
        var S = file.type;
        var teleType = "";
        if (S.includes("image")) {
          msgContent = url;
          msg_url = fileNameData;
          msgtype = 2;
          teleType = "image";
        } else if (S.includes("application")) {
          msgContent = url;
          msgtype = 3;
          msg_url = fileNameData;
          teleType = "document";
        } else if (S.includes("video")) {
          msgContent = url;
          msgtype = 4;
          msg_url = fileNameData;
          teleType = "video";
        } else {
          msgContent = url;
          msg_url = fileNameData;
          msgtype = 3;
          teleType = "document";
        }

        this.saveMessage(
          msgContent,
          msgtype,
          msg_url,
          contentType,
          chatID,
          filesize,
          "addmessage"
        );

        if (
          this.context.currentVisitor.current_pageUrl == "Telegram" &&
          teleType
        ) {
          var raw = JSON.stringify({
            msg: msgContent,
            token: this.agent.TeleToken,
            client_id: this.context.liveChatDocId,
            visitorId: this.context.midHeaderoperating,
            type: teleType,
          });

          var requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: raw,
            redirect: "follow",
          };

          fetch(
            "https://us-central1-livechat-production.cloudfunctions.net/telegram/sendmessage",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
        } else if (
          this.context.currentVisitor.current_pageUrl == "Whatsapp" &&
          teleType
        ) {
          var raw = JSON.stringify({
            msg: msgContent,
            token:
              this.agent.whatsapp && this.agent.whatsapp.whatsappAccessToken
                ? this.agent.whatsapp.whatsappAccessToken
                : "",
            client_id: this.context.liveChatDocId,
            visitorId:
              this.agent.whatsapp && this.agent.whatsapp.whatsappPhoneId
                ? this.agent.whatsapp.whatsappPhoneId
                : "",
            to: this.context.midHeaderoperating,
            type: teleType,
          });

          var requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: raw,
            redirect: "follow",
          };

          fetch(
            "https://us-central1-livechat-production.cloudfunctions.net/whatsapp/sendmessage",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
        } else if (
          this.context.currentVisitor.current_pageUrl == "metaFB" &&
          teleType
        ) {
          var raw = JSON.stringify({
            msg: msgContent,
            token: this.context.currentVisitor.system,
            client_id: this.context.liveChatDocId,
            visitorId: this.context.midHeaderoperating,
            to: this.context.midHeaderoperating,
            type: teleType,
          });

          var requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: raw,
            redirect: "follow",
          };

          fetch(
            "https://us-central1-livechat-production.cloudfunctions.net/metaFb/sendmessage",
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
        }

        filesPreview[counterImage - 1] = "";
        let tmpFilesPreview = filesPreview.filter((data222) => {
          return data222 === "" ? false : true;
        });
        filesPreviewChats[chatID] = tmpFilesPreview;
        var notempty = this.checkemptyData(tmpFilesPreview);
        if (notempty === false) {
          filesPreviewChats[chatID] = [];
          this.setState({ filesPreview: [], filesPreviewChats });
          if (chatID === this.context.liveChatDocId) {
            var btnClicked = this.sendButtonClickedIdes.indexOf(chatID);
            if (btnClicked !== -1) {
              this.sendButtonClickedIdes.splice(btnClicked, 1);
            }
            document.getElementById("fileUploader").value = ""; // Empty input field
            document.getElementById("App").style.display = "none";
            document.getElementById("DragShowHide").style.display = "none";
          }
        } else {
          this.setState({ filesPreview: tmpFilesPreview, filesPreviewChats });
        }
      })
      .catch((error) => {});
  }

  checkemptyData = (arrayvariable) => {
    var notempty = true;
    if (arrayvariable.length === 0) {
      notempty = false;
    }
    return notempty;
  };

  onDragLeave = () => {
    document.getElementById("dropZoneSection").style.display = "none";
    if (Object.keys(this.state.filesToBeSentChats).length > 0) {
      try {
        let filesAttachments =
          this.state.filesToBeSentChats[this.context.liveChatDocId];
        if (
          filesAttachments === undefined ||
          typeof filesAttachments === "undefined"
        ) {
          // Do Nothing.
        } else if (filesAttachments.length === 0) {
          document.getElementById("App").style.display = "none";
        }
      } catch (err) {}
    } else {
      document.getElementById("App").style.display = "none";
    }
  };

  /* Attachment functions End */

  /* Start Chat Functions start */

  startChat = async (chatId) => {
    if (this.context.goneOffline) {
      return false;
    }

    if (
      this.context.incoming_guide === false &&
      this.context.incoming_guide_step === 1
    ) {
      this.guidenextStep("incoming", 2, "incoming_guide_step");
    }

    this.setState({ startBtnText: "Starting..." });
    this.context.updateGlobalData("isDisableIncomingPanel", true);
    if (chatId.indexOf("TEMP") !== -1) {
      let msgData = {
        client_name: this.context.currentVisitor.client_name,
        client_email: this.context.currentVisitor.client_email,
        agent_short: this.context.currentVisitor.agent_short,
        alias: this.context.currentVisitor.alias,
        profile_img: this.context.currentVisitor.profile_img,
        toAgentId: this.context.currentVisitor.agent_ids[0],
        toAgentPhone: this.context.currentVisitor.client_phone,
      };
      let cChatID2 = await sendOrCreateAgentChat(
        this.agent,
        msgData,
        "outside"
      );
      setTimeout(() => {
        this.setState({ startBtnText: "" });
      }, 1500);
      let updataeArray = [
        { key: "callChatId", value: cChatID2 },
        { key: "isDisableIncomingPanel", value: false },
      ];
      this.context.updateGlobalData("array", updataeArray);
    } else {
      let userDetails = localStorage.getItem("agent")
        ? JSON.parse(localStorage.getItem("agent"))
        : {};
      var agentProfile = userDetails.profile_img
        ? encodeURIComponent(this.agent.profile_img)
        : "";
      var body = [
        `ownerId=${this.agent.ownerId}&chatId=${chatId}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentShort=${this.agent.agent_short}&fullName=${this.agent.agent_name}&profileimg=${agentProfile}&email=${this.agent.email}&canJoin=default`,
      ];
      fetch(MAIL_FUNCTION_URL + "/startChatByAgent", {
        method: "POST",
        body: body,
        headers: {
          //"X-API-Key" : FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          const status = response.status ? response.status : "";
          setTimeout(() => {
            this.setState({ startBtnText: "" });
          }, 500);
          if (status === "YOU_PICKED_CHAT") {
            this.context.updateGlobalData("picked_visitors", chatId);
            var noChatServed = parseInt(this.context.noChatServed) + 1;
            this.context.updateGlobalData("noChatServed", noChatServed);
            /* For whatsapp data adding start */
            saveClientPlan(this.context.currentVisitor);
            /* For whatsapp data adding start */
            this.setState({
              chatPickStatusTransaction: {
                key: chatId,
                status: "chatting",
                Text: "Continue",
                msgToShow: null,
              },
            });
            var array1 = [
              {
                key: "chatPickStatusTransaction",
                value: {
                  key: chatId,
                  status: "chatting",
                  Text: "Continue",
                  msgToShow: null,
                },
              },
              { key: "closeVisitorId", value: chatId },
              { key: "rightSectionItemToShow", value: "visitorslist" },
              { key: "isDisableIncomingPanel", value: false },
              { key: "isStartChatClicked", value: chatId },
              { key: "startClickedChatId", value: chatId },
            ];

            var curVisitor = this.context.currentVisitor;
            curVisitor.visitor_status = "ongoing";
            curVisitor.incoming = 2;
            array1.push({ key: "currentVisitor", value: curVisitor });
            let pickedFromVisitor = false;
            if (this.context.pickedFromVisitorId === chatId) {
              pickedFromVisitor = true;
              array1.push({
                key: "pickedFromVisitorId",
                value: "start_snapshot",
              });
            }
            array1.push({ key: "isStartChat", value: false });
            this.context.updateGlobalData("array", array1);

            /* For calling start */
            try {
              setCurrentChat(curVisitor);
            } catch (errrr) {}
            /* For calling end */

            let datObj = this.agent;
            datObj.pickedFromVisitor = pickedFromVisitor;
            datObj.incoming_picked_email = this.state.incoming_picked_email;
            updateAgentChatData(datObj, chatId, "response");
            try {
              document.getElementById("msg").focus();
            } catch (err) {
              //console.log(err);
            }
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatId)
              .collection("agent_data")
              .doc(this.agent.agentId)
              .set({
                addedon: new Date().getTime() + this.state.offset,
                agent_added_by: this.agent.agentId,
                agent_added_by_name: this.agent.name,
                agent_id: this.agent.agentId,
                agent_name: this.agent.name,
                agent_profile_img: this.agent.profile_img,
                status: 1,
                agent_ref: firebaseServices.db
                  .collection("users")
                  .doc(this.agent.ownerId)
                  .collection("agents")
                  .doc(this.agent.agentId),
                calling_status: this.agent.calling_status
                  ? this.agent.calling_status
                  : 0,
              });
          } else if (status === "OTHER_AGENT_PICKED_CHAT") {
            this.context.updateGlobalData("picked_visitors", chatId);
            var array2 = [
              {
                key: "chatPickStatusTransaction",
                value: {
                  key: chatId,
                  status: "close",
                  Text: "Close window",
                  msgToShow: "Chat has been picked by another agent",
                },
              },
              { key: "isDisableIncomingPanel", value: false },
              { key: "isStartChat", value: false },
            ];
            this.context.updateGlobalData("array", array2);
            this.setState({
              chatPickStatusTransaction: {
                key: chatId,
                status: "close",
                Text: "Close window",
                msgToShow: "Chat has been picked by another agent",
              },
            });
          } else if (status === "VISITOR_LEFT") {
            this.context.updateGlobalData("picked_visitors", chatId);
            var array3 = [
              {
                key: "chatPickStatusTransaction",
                value: {
                  key: chatId,
                  status: "close",
                  Text: "Close window",
                  msgToShow: "Visitor left the site.",
                },
              },
              { key: "isDisableIncomingPanel", value: false },
            ];
            this.context.updateGlobalData("array", array3);
            this.setState({
              chatPickStatusTransaction: {
                key: chatId,
                status: "close",
                Text: "Close window",
                msgToShow: "Visitor left the site.",
              },
            });
          } else {
            var array4 = [
              {
                key: "chatPickStatusTransaction",
                value: {
                  key: chatId,
                  status: "close",
                  Text: "Close window",
                  msgToShow: "Something went wrong.",
                },
              },
              { key: "isDisableIncomingPanel", value: false },
            ];
            this.context.updateGlobalData("array", array4);
            this.setState({
              chatPickStatusTransaction: {
                key: chatId,
                status: "close",
                Text: "Close window",
                msgToShow: "Something went wrong.",
              },
            });
          }
        })
        .catch((e) => {
          this.context.updateGlobalData("isDisableIncomingPanel", false);
          this.setState({ startBtnText: "" });
          //console.log(e, "ERROR");
        });
    }

    activityLog("Started chat");
  };

  /* Start Chat Functions End */

  /* Send Message functions star */

  sendChatMessage = async () => {
    let showOldFreePlan =
      this.context.showOldFreePlan === true && this.context.noChatServed < 500
        ? true
        : false;
    /* if (this.context.goneOffline || (this.context.showPaywall === true && !this.context.paymentDetails.paymentStatus && !showOldFreePlan)) {
			return false;
		} */

    /* remove this.context.goneOffline due to speed issue while sending messages */
    if (
      this.context.showPaywall === true &&
      !this.context.paymentDetails.paymentStatus &&
      !showOldFreePlan
    ) {
      return false;
    }

    console.log(this.context, "ISTHISTELEGRAM");
    if (this.context.currentVisitor.current_pageUrl == "Telegram") {
      if (document.getElementById("msg").innerText) {
        var raw = JSON.stringify({
          msg: document.getElementById("msg").innerText,
          token: this.agent.TeleToken,
          client_id: this.context.liveChatDocId,
          visitorId: this.context.midHeaderoperating,
        });

        var requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-livechat-production.cloudfunctions.net/telegram/sendmessage",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    } else if (this.context.currentVisitor.current_pageUrl == "Whatsapp") {
      if (document.getElementById("msg").innerText) {
        var raw = JSON.stringify({
          msg: document.getElementById("msg").innerText,
          token:
            this.agent.whatsapp && this.agent.whatsapp.whatsappAccessToken
              ? this.agent.whatsapp.whatsappAccessToken
              : "",
          client_id: this.context.liveChatDocId,
          visitorId:
            this.agent.whatsapp && this.agent.whatsapp.whatsappPhoneId
              ? this.agent.whatsapp.whatsappPhoneId
              : "",
          to: this.context.midHeaderoperating,
        });

        var requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-livechat-production.cloudfunctions.net/whatsapp/sendmessage",
          requestOptions
        )
          // fetch("http://localhost:5010/livechat-production/us-central1/whatsapp/sendmessage", requestOptions)

          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    } else if (this.context.currentVisitor.current_pageUrl == "metaFB") {
      if (document.getElementById("msg").innerText) {
        var raw = JSON.stringify({
          msg: document.getElementById("msg").innerText,
          token: this.context.currentVisitor.system,
          client_id: this.context.liveChatDocId,
          visitorId: this.context.midHeaderoperating,
        });

        var requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-livechat-production.cloudfunctions.net/metaFb/sendmessage",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    }

    /* For canned text Start */

    this.selectionStart = 0;
    this.setState({
      showCannedResp: false,
      cannedList: [],
    });
    // Reset the divs after sending the message
    this.predictionFlag = "Incomplete";
    this.cannedTextDetectedFlag = false;
    this.predictedTerm = "";
    this.currentCursorPos = 0;
    this.cannedTextInfoFlag = "noCannedText";
    try {
      document.getElementById("predictedTermId").innerText = "";
    } catch {}
    this.previousSearchText = (Math.random() + 1).toString(36).substring(2);
    /* For canned text End */
    try {
      document.getElementById(this.state.predictableContainerId).innerHTML = "";
    } catch (errrr) {}

    let chatId = this.context.liveChatDocId;
    if (chatId.indexOf("TEMP") !== -1) {
      if (this.context.goneOffline) {
        return false;
      }
      try {
        document.getElementById("predictedTermId").innerText = "";
      } catch {}
      let msg = document.getElementById("msg").innerText;
      msg = msg.trim();
      if (msg !== "") {
        let msgData = {
          message: msg,
          client_name: this.context.currentVisitor.client_name,
          client_email: this.context.currentVisitor.client_email,
          agent_short: this.context.currentVisitor.agent_short,
          alias: this.context.currentVisitor.alias,
          profile_img: this.context.currentVisitor.profile_img,
          toAgentId: this.context.currentVisitor.agent_ids[0],
          toAgentPhone: this.context.currentVisitor.client_phone,
        };
        let cChatID2 = await sendOrCreateAgentChat(
          this.agent,
          msgData,
          "outside"
        );
        this.context.updateGlobalData("callChatId", cChatID2);
      }
    } else {
      if (Object.keys(this.context.currentVisitor).length > 0) {
        let agent_end = this.context.currentVisitor.agent_end
          ? this.context.currentVisitor.agent_end
          : "";
        let isChatend = this.context.currentVisitor.isChatend
          ? this.context.currentVisitor.isChatend
          : 0;
        if (agent_end === "VISITOR_END_CHAT" || isChatend === 1) {
          return false;
        }
      }

      // For Draft massages Start //////
      try {
        let tmpMsgObj = this.state.chatMessages ? this.state.chatMessages : {};
        if (tmpMsgObj.hasOwnProperty(chatId)) {
          delete tmpMsgObj[chatId];
          this.setState({ chatMessages: tmpMsgObj });
        }
      } catch (err) {}
      // For Draft massages End //////

      // var msgData = document.getElementById(this.state.editorId).innerText;
      // var msgData = document.getElementById("msg").value;
      var msgData = document.getElementById("msg").innerText;
      // document.getElementById("msg").style.height = "46px";
      msgData = msgData.trim();
      let filesToBeSentChats = this.state.filesToBeSentChats;
      if (filesToBeSentChats[chatId] && !this.context.goneOffline) {
        if (filesToBeSentChats[chatId].length > 0) {
          if (this.sendButtonClickedIdes.indexOf(chatId) === -1) {
            this.sendButtonClickedIdes.push(chatId);
          }
          this.context.updateGlobalData("isDisableIncomingPanel", true);
          this.startUploadImage(filesToBeSentChats[chatId], chatId);
          filesToBeSentChats[chatId] = [];
          this.setState({ filesToBeSentChats }, () => {
            setTimeout(() => {
              this.context.updateGlobalData("isDisableIncomingPanel", false);
            }, 500);
          });
        }
      }
      if (msgData !== "") {
        var msg_url = "";
        let liveChatDocId = this.context.liveChatDocId
          ? this.context.liveChatDocId
          : "";
        let changeTextLang =
          this.context.changeTextLangArr &&
          this.context.changeTextLangArr[liveChatDocId]
            ? this.context.changeTextLangArr[liveChatDocId]
            : "";
        this.saveMessage(
          msgData,
          1,
          msg_url,
          "",
          chatId,
          0,
          "addmessage",
          changeTextLang
        );

        const element = document.getElementById("msg");
        // element.value = '';
        element.innerText = "";
        autosize(element);
        element.focus();
        this.update(element);
        // document.getElementById(this.state.editorId).innerText= "";
        // document.getElementById(this.state.editorId).focus();
      }

      this.closeEmojis();
    }
  };

  removeReadCounts = (elemId = "msg") => {
    if (elemId === "msg") {
      let mainInput = document.getElementById("msg");
      let nodes = mainInput.childNodes;
      if (nodes.length > 0)
        this.setCaretPosition(nodes[nodes.length - 1].length, nodes.length - 1); // go to last position of last node when you onFocus msg
    }
    var notCount = this.context.currentVisitor[this.agent.agentId]
      ? parseInt(this.context.currentVisitor[this.agent.agentId])
      : 0;
    if (this.context.liveChatDocId && notCount > 0) {
      this.readCount(this.context.liveChatDocId);
    }
  };

  saveMessage = async (
    msgContent,
    msg_type,
    msg_url,
    contentType,
    chatId,
    filesize,
    actionType,
    transLatedLang = ""
  ) => {
    var agentProfile = "";
    var tmpmsgContent = "";
    if (chatType === "voip" || SWITCH_TO_CLOUD_FUNCTION === true) {
      agentProfile = encodeURIComponent(this.agent.profile_img);
      tmpmsgContent = encodeURIComponent(msgContent);
    } else {
      agentProfile = this.agent.profile_img;
      tmpmsgContent = msgContent;
    }
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};
    var chatType = tmpCurVisitor.start_url ? tmpCurVisitor.start_url : "";
    var agent_start = tmpCurVisitor.agent_start
      ? tmpCurVisitor.agent_start
      : "";
    var agent_ids = tmpCurVisitor.agent_ids ? tmpCurVisitor.agent_ids : [];
    if (chatType === "voip_call") {
      chatType = "voip";
    } else {
      chatType = "chat";
    }

    let userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    if (chatType === "voip" || SWITCH_TO_CLOUD_FUNCTION === true) {
      agentProfile = userDetails.profile_img
        ? encodeURIComponent(userDetails.profile_img)
        : "";
    } else {
      agentProfile = userDetails.profile_img;
    }

    ///////////////// Start sending message start //////////////////

    if (chatType === "voip" || SWITCH_TO_CLOUD_FUNCTION === true) {
      let body = [
        `ownerId=${this.agent.ownerId}&chatId=${chatId}&agentId=${
          this.agent.agentId
        }&agentName=${
          this.agent.name
        }&agentProfile=${agentProfile}&agentShort=${nameIntials(
          this.agent.name
        )}&msg=${tmpmsgContent}&msg_type=${msg_type}&msg_url=${msg_url}&contenttype=${contentType}&filesize=${filesize}&action=${actionType}&chatType=${chatType}&agent_start=${agent_start}&agents=${JSON.stringify(
          agent_ids
        )}&changeTextLang=${transLatedLang}`,
      ];
      fetch(GATEWAY_URL + "/saveMessage", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then((response) => {
          const status = response.result ? response.result : "";

          /* Check first message time start */
          if (
            status === "success" &&
            this.firstRespChatArray.indexOf(chatId) === -1
          ) {
            this.firstRespChatArray.push(chatId);
            let datObj = this.agent;
            datObj.currentVisitor = tmpCurVisitor;
            updateAgentChatData(datObj, chatId, "first_msg_response");
          }
          /* Check first message time end */
        })
        .catch((err) => {});
    } else {
      const serverTimestamp23 = await getDateInUTC();
      let docId =
        "LIVE" + serverTimestamp23 + Math.ceil(Math.random() * 999999);
      docId = docId.toString();
      let dataObj = {
        addedon: parseInt(serverTimestamp23),
        agent_id: this.agent.agentId,
        agent_profile_img: agentProfile,
        agent_name: this.agent.name || this.agent.agent_name || "Agent",
        msg: tmpmsgContent,
        msg_type: parseInt(msg_type),
        msg_read_status: 1,
        msg_url: msg_url,
        contenttype: contentType,
        agent_short: nameIntials(this.agent.name),
        filesize: parseInt(filesize),
        action: actionType,
      };

      if (transLatedLang) {
        let msgs = await this.getTranslatedText(transLatedLang, tmpmsgContent);
        if (msgs.status === 200) {
          dataObj.translated_msg = msgs.msg;
          dataObj.translated_lang = transLatedLang;
        }
      }

      firebaseServices.db
        .collection("users")
        .doc(this.agent.ownerId)
        .collection("visitors")
        .doc(chatId)
        .collection("chat_data")
        .doc(docId)
        .set(dataObj)
        .then(() => {
          /* Check first message time start */
          if (this.firstRespChatArray.indexOf(chatId) === -1) {
            this.firstRespChatArray.push(chatId);
            let datObj22 = this.agent;
            datObj22.currentVisitor = tmpCurVisitor;
            updateAgentChatData(datObj22, chatId, "first_msg_response");
          }
          /* Check first message time end */
        })
        .catch(() => {});
    }

    ///////////////// sending message end //////////////////

    var docRef = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("visitors")
      .doc(chatId);
    docRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          var visitorData = doc.data();
          const chatagentIds = visitorData.agent_ids;
          const leaveAgentsIds = visitorData.agent_leave;
          const increment = firebase1.firestore.FieldValue.increment(1);
          var agentReference = firebaseServices.db
            .collection("users")
            .doc(this.agent.ownerId)
            .collection("agents")
            .doc(this.agent.agentId);

          var content = msgContent;
          if (msg_type === 1) {
            content = msgContent;
          } else if (msg_type === 2) {
            content = "Image";
          } else if (msg_type === 4) {
            content = "Video";
          } else if (msg_type === 6) {
            content = msgContent;
          } else if (msg_type === 7) {
            content = msgContent;
          } else {
            content = "File";
          }
          var includeClientId = chatagentIds.includes(this.agent.agentId);
          this.readCount(chatId);
          var serverTimestamp = await getDateInUTC();

          //// Update Last Activity Start ///////
          updateAgentLastActivityTime(); // add last activity time on each page when agent is Visit
          //// Update Last Activity End  ///////

          if (
            includeClientId === false &&
            leaveAgentsIds.indexOf(this.agent.agentId) === -1
          ) {
            chatagentIds.push(this.agent.agentId);
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatId)
              .collection("agent_data")
              .doc(this.agent.agentId)
              .set({
                addedon: new Date().getTime() + this.state.offset,
                agent_added_by: this.agent.agentId,
                agent_added_by_name: this.agent.name,
                agent_id: this.agent.agentId,
                agent_name: this.agent.name,
                agent_profile_img: this.agent.profile_img,
                status: 1,
                agent_ref: agentReference,
                calling_status: this.agent.calling_status
                  ? this.agent.calling_status
                  : 0,
              })
              .then((ref) => {})
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
            docRef.update({
              agent_ids: chatagentIds,
              updatedon: serverTimestamp,
              latest_msg: content,
              front_read_count: increment,
              translated_lang: transLatedLang,
            });
            agentReference.update({ chat_total: increment });
          } else {
            docRef.update({
              updatedon: serverTimestamp,
              latest_msg: content,
              front_read_count: increment,
              translated_lang: transLatedLang,
            });
          }
          //////////// End //////////////////////////////
        }
      })
      .catch(function (error) {
        console.log("Error saving document:", error);
      });
  };

  getTranslatedText = (lang, msg) => {
    return new Promise((resolve, reject) => {
      if (lang && msg) {
        let msgs = msg.replace(/\n/g, "<br />");
        fetch(
          "https://translation.googleapis.com/language/translate/v2?q=" +
            encodeURIComponent(msgs) +
            "&format=text&key=" +
            GOOGLE_TRANSLATION_KEY +
            "&target=" +
            lang
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.data && result.data.translations) {
              var tt = result.data.translations[0].translatedText;
              tt = String(tt)
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;");
              resolve({ status: 200, msg: tt });
            } else {
              resolve({ status: 400 });
            }
          })
          .catch((err) => {
            console.log(err);
            resolve({ status: 400 });
          });
      } else {
        resolve({ status: 400 });
      }
    });
  };

  saveMessage_OLD = async (
    msgContent,
    msg_type,
    msg_url,
    contentType,
    chatId,
    filesize,
    actionType,
    transLatedLang = ""
  ) => {
    var agentProfile = encodeURIComponent(this.agent.profile_img);
    var tmpmsgContent = encodeURIComponent(msgContent);
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};
    var chatType = tmpCurVisitor.start_url ? tmpCurVisitor.start_url : "";
    var agent_start = tmpCurVisitor.agent_start
      ? tmpCurVisitor.agent_start
      : "";
    var agent_ids = tmpCurVisitor.agent_ids ? tmpCurVisitor.agent_ids : [];
    if (chatType === "voip_call") {
      chatType = "voip";
    } else {
      chatType = "chat";
    }

    let userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    agentProfile = userDetails.profile_img
      ? encodeURIComponent(userDetails.profile_img)
      : "";
    ///////////////// Starting sending message start //////////////////
    var body = [
      `ownerId=${this.agent.ownerId}&chatId=${chatId}&agentId=${
        this.agent.agentId
      }&agentName=${
        this.agent.name
      }&agentProfile=${agentProfile}&agentShort=${nameIntials(
        this.agent.name
      )}&msg=${tmpmsgContent}&msg_type=${msg_type}&msg_url=${msg_url}&contenttype=${contentType}&filesize=${filesize}&action=${actionType}&chatType=${chatType}&agent_start=${agent_start}&agents=${JSON.stringify(
        agent_ids
      )}&changeTextLang=${transLatedLang}`,
    ];
    fetch(GATEWAY_URL + "/saveMessage", {
      method: "post",
      body: body,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const status = response.result ? response.result : "";

        /* Check first message time start */
        if (
          status === "success" &&
          this.firstRespChatArray.indexOf(chatId) === -1
        ) {
          this.firstRespChatArray.push(chatId);
          let datObj = this.agent;
          datObj.currentVisitor = tmpCurVisitor;
          updateAgentChatData(datObj, chatId, "first_msg_response");
        }
        /* Check first message time end */
      })
      .catch((err) => {});

    ///////////////// Starting sending message end //////////////////

    var docRef = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("visitors")
      .doc(chatId);
    docRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          var visitorData = doc.data();
          const chatagentIds = visitorData.agent_ids;
          const leaveAgentsIds = visitorData.agent_leave;
          const increment = firebase1.firestore.FieldValue.increment(1);
          var agentReference = firebaseServices.db
            .collection("users")
            .doc(this.agent.ownerId)
            .collection("agents")
            .doc(this.agent.agentId);

          var content = msgContent;
          if (msg_type === 1) {
            content = msgContent;
          } else if (msg_type === 2) {
            content = "Image";
          } else if (msg_type === 4) {
            content = "Video";
          } else if (msg_type === 6) {
            content = msgContent;
          } else if (msg_type === 7) {
            content = msgContent;
          } else {
            content = "File";
          }
          var includeClientId = chatagentIds.includes(this.agent.agentId);
          this.readCount(chatId);
          var serverTimestamp = await getDateInUTC();

          //// Update Last Activity Start ///////
          updateAgentLastActivityTime(); // add last activity time on each page when agent is Visit
          //// Update Last Activity End  ///////

          if (
            includeClientId === false &&
            leaveAgentsIds.indexOf(this.agent.agentId) === -1
          ) {
            chatagentIds.push(this.agent.agentId);
            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatId)
              .collection("agent_data")
              .doc(this.agent.agentId)
              .set({
                addedon: new Date().getTime() + this.state.offset,
                agent_added_by: this.agent.agentId,
                agent_added_by_name: this.agent.name,
                agent_id: this.agent.agentId,
                agent_name: this.agent.name,
                agent_profile_img: this.agent.profile_img,
                status: 1,
                agent_ref: agentReference,
                calling_status: this.agent.calling_status
                  ? this.agent.calling_status
                  : 0,
              })
              .then((ref) => {})
              .catch((error) => {
                console.error("Error adding document: ", error);
              });
            docRef.update({
              agent_ids: chatagentIds,
              updatedon: serverTimestamp,
              latest_msg: content,
              front_read_count: increment,
              translated_lang: transLatedLang,
            });
            agentReference.update({ chat_total: increment });
          } else {
            docRef.update({
              updatedon: serverTimestamp,
              latest_msg: content,
              front_read_count: increment,
              translated_lang: transLatedLang,
            });
          }
          //////////// End //////////////////////////////
        }
      })
      .catch(function (error) {
        console.log("Error saving document:", error);
      });
  };

  replaceBetween = (originalString, startIndex, endIndex, replaceFrom) => {
    return (
      originalString.substring(0, startIndex) +
      replaceFrom +
      originalString.substring(endIndex)
    );
  };

  /* function to get caret position by ravi sane */
  getCaretPositionCanned() {
    //
    var sel = document.getSelection();
    //paragraphboundary option in modify method has been replaced with word to be work in firefox
    sel.modify("extend", "backward", "word");
    var pos = sel.toString().length;
    if (sel.anchorNode !== undefined) sel.collapseToEnd();
    return pos;
  }

  checkCannedSmartCompose = async (event) => {
    // * logic for canned text
    // console.log("💡🛠 -> file: footer.js -> line 2081 -> this.cannedTextInfoFlag", this.cannedTextInfoFlag);

    event.persist();
    //if (userDetails && userDetails.canned_response === 1) {
    // if '/' is pressed, then start canned text
    if (event.keyCode === 8 && this.cannedTextInfoFlag === "searchingStarted") {
      this.cannedTextInfoFlag = "backspacePressed";
      this.previousSearchText = (Math.random() + 1).toString(36).substring(2);
    }
    if (
      event.keyCode === 32 &&
      this.cannedTextInfoFlag === "searchingStarted"
    ) {
      this.cannedTextInfoFlag = "noCannedText";
    }
    if (this.keyPressed === "/" && this.cannedTextDetectedFlag === false) {
      this.selectionStart = this.getCaretPositionCanned();
      this.cannedTextDetectedFlag = true;
      this.previousSearchText = (Math.random() + 1).toString(36).substring(2);
      this.cannedTextInfoFlag = "searchingStarted";
    }
    if (
      this.cannedTextDetectedFlag === true ||
      this.cannedTextInfoFlag === "backspacePressed"
    ) {
      this.selectionEnd = this.getCaretPositionCanned();
      this.cannedTextDetectedFlag = true;
      this.cannedTextInfoFlag = "searchingStarted";

      const ignoredKeys = [13, 52, 51]; // * why 52 and 51 used, dont know, removed 32 from the list of ignored
      const msgValue = event.target.innerText
        ? event.target.innerText
        : event.target.value; // changed the default condition
      // if the msg is empty or enter/space is pressed...reset canned text
      if (ignoredKeys.indexOf(event.keyCode) > -1 || msgValue === "") {
        this.selectionStart = 0;
        this.setState({
          cannedList: [],
          showCannedResp: false,
        });
        this.cannedTextDetectedFlag = false;
      }
      // if cursor position is not at zero, then run canned text
      if (this.selectionStart > 0) {
        // console.log(">>------------------ Canned Text Started -------------------->>");
        // console.log("💡🛠 || file: footer.js || line 2057 || this.selectionStart", this.selectionStart);
        // console.log("💡🛠 || file: footer.js || line 2057 || this.selectionEnd", this.selectionEnd);

        let tempSelection = window.getSelection();
        let tempMsgValue = tempSelection.anchorNode.data;
        // console.log("💡🛠 -> file: footer.js -> line 2161 -> tempMsgValue", tempMsgValue);
        var searchText = "";
        if (tempMsgValue !== undefined) {
          this.setState({ currentChildNode: tempMsgValue });
          searchText = tempMsgValue.substring(
            this.selectionStart,
            this.selectionEnd
          ); //* .trim();
        }
        // if searchText is not empty or (cursor Start and End is same)
        if (searchText !== "" || this.selectionStart === this.selectionEnd) {
          let agentCheck = true;
          if (this.userRole === "owner" || this.userRole === "admin") {
            agentCheck = false;
          }
          this.setState({
            searchTextLength: searchText.length,
            searchText: searchText,
          });
          const userDetails = localStorage.getItem("agent")
            ? JSON.parse(localStorage.getItem("agent"))
            : {};
          const ownerId = userDetails.ownerId ? userDetails.ownerId : false;
          const agentId = userDetails.agentId ? userDetails.agentId : false;
          const currentPage = 1;
          const cannedTextList = await getCannedText(
            ownerId,
            agentId,
            agentCheck,
            searchText,
            currentPage,
            100,
            "livechat"
          );
          // console.log("💡🛠 -> file: footer.js -> line 2178 -> searchText", searchText);
          // console.log("💡🛠 -> file: footer.js -> line 2178 -> cannedTextList", cannedTextList);
          const slashIndex = msgValue.indexOf("/");
          let data = [];
          if (
            cannedTextList.status === 200 &&
            this.selectionStart > 0 &&
            slashIndex > -1
          ) {
            data = cannedTextList.data;
          }
          this.setState(
            {
              cannedList: data,
              showCannedResp: true,
            },

            () => {
              if (
                this.previousSearchText !== searchText && // if previous text is not equal to current & data from API is there
                this.cannedTextDetectedFlag === true
                // && data.length > 0
              ) {
                this.previousSearchText = searchText;
                try {
                  // console.log('running callFunction');
                  this.dropdownListCannedText(data, event.keyCode);
                } catch (err) {
                  console.log("ERROR: ", err);
                  this.previousSearchText = (Math.random() + 1)
                    .toString(36)
                    .substring(2);
                }
              }
            }
          );
        }
      }
    }
    // }
    //* set to 1 to enable this feature for only paid users
    if (this.agent.smart_compose === 1) {
      var mainInput = document.getElementById("msg");
      if (this.togglePrediction === 1) {
        //* new code Logic starts here
        //* Run javascript function when user finishes typing instead of on key up
        let self = this; // used to send reference
        //on keyup, start the countdown
        if (event.keyCode === 32) {
          // if space is pressed
          clearTimeout(this.typingTimer);
          if (mainInput.innerText) {
            this.typingTimer = setTimeout(
              doneTyping,
              this.state.doneTypingInterval
            );
          }
        }

        //user is "finished typing," do something
        function doneTyping() {
          // * logic for smart compose using OPENAI METHOD with delay
          self.userTypingFlag = false;
          var position = self.getCaretPositionCanned();

          if (
            (position === mainInput.innerText.length || // cursor position is equal to text length or not
              position ===
                mainInput.childNodes[mainInput.childNodes.length - 1].length) && // cursor position is equal to last childNode length or not
            self.predictionFlag !== "Complete"
          ) {
            // console.log(">>------------------ Smart Compose Started ------------------>>");
            // self.CallMLDataSetAPI();
            self.mainInputBeforeCursor = mainInput.innerText;
            self.findPredicted();
          }
        }

        //* LOGIC ENDS HERE
      } else {
        // * logic for smart compose using inbuilt search option
        if (event.keyCode === 32) {
          // if space is pressed
          var position = this.getCaretPositionCanned();

          if (
            (position === mainInput.innerText.length || // cursor position is equal to text length or not
              position ===
                mainInput.childNodes[mainInput.childNodes.length - 1].length) && // cursor position is equal to last childNode length or not
            this.predictionFlag !== "Complete"
          ) {
            // console.log(">>------------------ Smart Compose Started ------------------>>");
            // this.CallMLDataSetAPI();
            this.mainInputBeforeCursor = mainInput.innerText;
            this.findPredicted2();
          }
        }
        // * LOGIC ENDS HERE
      }
    }
    // this.currentCursorPos = this.getCaretPositionCanned();
    this.update(event.target);
    return;
  };
  // // edited by Ravi Sane
  // CallMLDataSetAPI = () => {
  // 	this.findPredicted();     //* --> OPENAI METHOD without delay
  // 	this.findPredicted2();    //* --> LOCAL SEARCH METHOD
  // };

  togglePredictionMethod = () => {
    if (this.togglePrediction === 1) {
      this.togglePrediction = 0;
      this.setState({ predictionTypeValue: "Click for OpenAI Prediction" });
    } else {
      this.togglePrediction = 1;
      this.setState({ predictionTypeValue: "Click for Inbuilt Prediction" });
    }
  };

  setCaretPosition(position, children = 0) {
    var el = document.getElementById("msg");
    var range = document.createRange();
    var sel = window.getSelection();
    range.setStart(el.childNodes[children], position);

    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    el.focus();
  }

  saveCanned = async () => {
    this.setState({
      loader: true,
    });
    const userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    const ownerId = userDetails.ownerId ? userDetails.ownerId : false;
    const agentId = userDetails.agentId ? userDetails.agentId : false;
    const userType = userDetails.roleType === "Owner" ? "owner" : "agent";
    const { cannedText, cannedHashTag, cannedApplicableOn } = this.state;
    const cannedResponse = await addCannedText(
      ownerId,
      agentId,
      cannedText,
      cannedHashTag,
      cannedApplicableOn,
      userType
    );
    if (cannedResponse.success === true) {
      const canned = {
        canned_text: cannedText,
      };
      //this.replaceCanned(canned, 'add');
    } else {
      this.setState({
        loader: false,
      });
      toast.error("Something went wrong, Please try again after some time");
    }
  };

  hideAddCanned = () => {
    this.setState({
      cannedText: "",
      cannedHashTag: "",
      cannedApplicableOn: 1,
      showAddCanned: false,
    });
  };

  /* Added canned Response by Dharmesh ends here */
  resetSmartCompose(element) {
    // reset the values after using them
    this.mainInputBeforeCursor = "";
    this.predictedTerm = "";
    autosize(element);
    element.focus();
    this.update(element);
  }

  checkEnterTab = (event) => {
    // console.log("this.cannedTextDetectedFlag -->", this.cannedTextDetectedFlag,
    // 	"\nthis.predictionFlag -->", this.predictionFlag,
    // 	"\nevent -->", event.key);
    // this.currentCursorPos = this.getCaretPositionCanned();
    this.keyPressed = event.key;
    this.closeEmojis();
    this.userTypingFlag = true;
    //* logic for canned text list
    if (this.cannedTextDetectedFlag === true) {
      if (event.keyCode === 38 || event.keyCode === 40) {
        // up and down arrow keys
        event.preventDefault();
        return;
      } else if (event.keyCode === 13) {
        // enter key
        event.preventDefault();
        this.cannedTextDetectedFlag = false;
        return;
      } else if (event.keyCode === 191) {
        // forwardslash key pressed
        this.cannedTextDetectedFlag = false;
        return;
      }
    } else {
      //* logic to Accept the smart compose text by clicking tab, right arrow, down arrow,
      if (
        (event.keyCode === 9 || event.keyCode === 39 || event.keyCode === 40) &&
        this.predictionFlag === "Complete"
      ) {
        event.preventDefault();
        this.pressTabKey();
      }
      //* logic to Reject the smart compose text by not clicking tab, right arrow, down arrow
      else if (
        event.keyCode !== 9 &&
        event.keyCode !== 39 &&
        event.keyCode !== 40 &&
        this.predictionFlag === "Complete"
      ) {
        try {
          //* logic if user types same character as suggested in the predicted term
          if (
            event.key.toLowerCase() === this.predictedTerm[0].toLowerCase() ||
            (event.key === " " &&
              this.predictedTerm[0] === String.fromCharCode(160))
          ) {
            // compare space with &nbsp;
            this.predictionFlag = "Complete";
            if (event.key.length === 1) this.mainInputBeforeCursor += event.key;
            else {
              event = event || window.event;
              var charCode = event.keyCode || event.which;
              var charStr = String.fromCharCode(charCode);
              this.mainInputBeforeCursor += charStr;
            }

            let newPredictedTerm = this.predictedTerm.slice(1);
            document.getElementById("predictedTermId").innerText =
              newPredictedTerm;
            this.predictedTerm = newPredictedTerm;
          }
          //* when backspace is pressed
          else if (event.keyCode === 8) {
            //* when backspace is pressed and check whether the input is undefined or start of sentence reached or &nbsp detected
            if (
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 2
              ] === undefined ||
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 3
              ] === "." ||
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 3
              ] === "!" ||
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 3
              ] === "?" ||
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 1
              ] === String.fromCharCode(160)
            ) {
              this.predictionFlag = "Incomplete";
              let mainInput = document.getElementById("msg");
              let nodes = mainInput.childNodes;
              mainInput.removeChild(nodes[nodes.length - 1]);
              this.resetSmartCompose(mainInput);
            }
            //* when backspace is pressed re-show the previous prediction
            else {
              this.predictionFlag = "Complete";
              let newPredictedTerm =
                this.mainInputBeforeCursor[
                  this.mainInputBeforeCursor.length - 1
                ] + this.predictedTerm;
              document.getElementById("predictedTermId").innerText =
                newPredictedTerm;
              this.predictedTerm = newPredictedTerm;
              this.mainInputBeforeCursor = this.mainInputBeforeCursor.slice(
                0,
                this.mainInputBeforeCursor.length - 1
              );
            }
          }
          //* when space is pressed
          else if (
            event.keyCode === 32 &&
            this.getCaretPositionCanned() === this.mainInputBeforeCursor.length
          ) {
            // (position === mainInput.innerText.length || // cursor position is equal to text length or not
            // 	position === mainInput.childNodes[mainInput.childNodes.length - 1].length) && // cursor position is equal to last childNode length or not
            // 	this.predictionFlag !== 'Complete'
            this.predictionFlag = "Complete";
            if (
              this.mainInputBeforeCursor[
                this.mainInputBeforeCursor.length - 1
              ] === " "
            )
              this.mainInputBeforeCursor +=
                String.fromCharCode(160); // add &nbsp; to div
            else this.mainInputBeforeCursor += " "; // add space to div
          }
          //* when other key is pressed after completion prediction
          else {
            this.predictionFlag = "Incomplete";
            let mainInput = document.getElementById("msg");
            let nodes = mainInput.childNodes;
            mainInput.removeChild(nodes[nodes.length - 1]);
            this.resetSmartCompose(mainInput);
          }
        } catch (e) {
          //* when other key is pressed except the character key after completion prediction
          console.log("error: " + e.message);
          this.predictionFlag = "Incomplete";
          let mainInput = document.getElementById("msg");
          try {
            let nodes = mainInput.childNodes;
            mainInput.removeChild(nodes[nodes.length - 1]); // todo attend this if any error arise
          } catch (e) {
            console.log("error: " + e.message);
          }
          this.resetSmartCompose(mainInput);
        }
      }
    }

    if (!this.checkResizeRun) {
      this.checkResizeRun = true;
      autosize(event.target);
    }

    //* SHIFT ENTER and ENTER Part STARTS HERE
    if (event.target.textContent.trim() !== "") {
      let visId2222 = this.context.liveChatDocId
        ? this.context.liveChatDocId
        : "";
      if (visId2222.indexOf("TEMP") === -1) {
        if (!this.state.isTyping) {
          // firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(this.context.liveChatDocId).collection('typing_status').doc(this.agent.name).set({ isTyping: true });
          this.setState({ isTyping: true });
        }
        this.setTypingFalse();
      }

      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          // if only enter is pressed then send message
          event.target.style.height = "46px";
          event.preventDefault();
          this.sendChatMessage();

          // Reset the divs after sending the message
          this.predictionFlag = "Incomplete";
          this.cannedTextDetectedFlag = false;
          this.keyPressed = "";
          return;
        } else {
          // if shift enter is pressed then increase size of TextArea
          autosize(event.target);
        }
      }
    } else if (this.state.filesToBeSent.length > 0) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.sendChatMessage();

        // Reset the divs after sending the message
        this.predictionFlag = "Incomplete";
        this.cannedTextDetectedFlag = false;
        this.keyPressed = "";
        return;
      }
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();

        // Reset the divs after sending the message
        this.predictionFlag = "Incomplete";
        this.cannedTextDetectedFlag = false;
        this.keyPressed = "";
      }
    }
    // SHIFT ENTER and ENTER Part STARTS ENDS HERE
  };

  draftMessages = (elemId = "msg") => {
    const allChatMsges = this.state.chatMessages;
    var msg;
    if (elemId === "msg") {
      msg = document.getElementById(elemId).innerText;
      this.predictionFlag = "Incomplete";
      this.predictedTerm = "";
    } else {
      msg = document.getElementById(elemId).value;
    }
    msg = msg.trim();
    if (msg) {
      allChatMsges[this.context.liveChatDocId] = msg;
      this.setState({ chatMessages: allChatMsges });
    } else {
      if (allChatMsges.hasOwnProperty(this.context.liveChatDocId)) {
        delete allChatMsges[this.context.liveChatDocId];
        this.setState({ chatMessages: allChatMsges });
      }
    }
  };

  pasteData = (event) => {
    event.preventDefault();
    var text = event.clipboardData.getData("text/plain");

    let chatId = this.context.liveChatDocId;
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    var fileEnable = false;
    var filesToBeSentChats = this.state.filesToBeSentChats;
    var filesToBeSent = [];
    if (filesToBeSentChats[chatId]) {
      filesToBeSent = filesToBeSentChats[chatId];
    }
    // event.preventDefault();
    if (text.trim() === "") {
      event.preventDefault();
      for (const index in items) {
        var item = items[index];
        if (
          item.kind === "file" &&
          (item.type.indexOf("image") !== -1 ||
            validFileFormates.indexOf(item.type) !== -1)
        ) {
          var blob = item.getAsFile();
          if (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            filesToBeSent.push(blob);
            if (filesToBeSent.length <= this.state.printcount) {
              filesToBeSentChats[chatId] = filesToBeSent;
              this.setState({ filesToBeSent, filesToBeSentChats }, () =>
                this.fileCount(filesToBeSentChats[chatId], chatId)
              );
            } else {
              console.log(
                "You have reached the limit of uploading files at a time"
              );
            }
            fileEnable = true;
          }
        }
      }
    }

    if (fileEnable === false) {
      document.execCommand("insertHTML", false, text);
      /*var msg = document.getElementById("msg").value;
			msg = msg + event.clipboardData.getData('text/plain');
			document.getElementById("msg").value = msg; */
      // document.getSelection().getRangeAt(0).insertNode(document.createTextNode(plainText));
    } else {
      document.getElementById("dropZoneSection").style.display = "none";
    }
  };

  suggestionOnChange = (event) => {
    const suggestions = autoKeyMessages;
    const userInput = event.target.value;

    if (userInput.trim() !== "") {
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: userInput,
      });
    } else {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: "",
      });
    }
  };
  setTypingFalse = () => {
    const docId = this.context.liveChatDocId
      ? this.context.liveChatDocId
      : false;
    if (!this.state.isTyping && docId) {
      var runInterval = setInterval(() => {
        try {
          var message = document.getElementById("msg").innerText;
          // var message = document.getElementById("msg").value;
          message = message.trim();
          this.previousMsgCounter = eval(this.previousMsgCounter) + 1;
          if (this.previousMessage !== message) {
            this.previousMessage = message;
            this.previousMsgCounter = 1;
          }
          if (message === "" && this.state.isTyping) {
            clearInterval(runInterval);
            // firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(docId).collection('typing_status').doc(this.agent.name).set({ isTyping: false });
            this.previousMsgCounter = 0;
            this.previousMessage = "";
            this.setState({ isTyping: false });
          } else if (
            this.previousMsgCounter === 7 &&
            message !== "" &&
            this.state.isTyping
          ) {
            clearInterval(runInterval);
            // firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(docId).collection('typing_status').doc(this.agent.name).set({ isTyping: false });
            this.previousMsgCounter = 0;
            this.previousMessage = "";
            this.setState({ isTyping: false });
          }
        } catch (err) {
          try {
            clearInterval(runInterval);
            // firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(docId).collection('typing_status').doc(this.agent.name).set({ isTyping: false });
            this.previousMsgCounter = 0;
            this.previousMessage = "";
            this.setState({ isTyping: false });
          } catch (err2) {}
        }
      }, 2000);
    }
  };

  readCount = (chatId) => {
    var jsonData = {};
    jsonData[this.agent.agentId] = 0;
    firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("visitors")
      .doc(chatId)
      .update(jsonData);
  };

  /* Send Message functions End */

  /* End Current Chat Function start */

  endChat = (chatID, closeTicketing = "") => {
    if (this.context.goneOffline) {
      return false;
    }

    // if (true){ //Telegram
    // 	end_chat

    // }
    console.log(this.context.currentVisitor, "ISTHISTELEGRAMEND");

    if (this.context.currentVisitor.current_pageUrl == "Telegram") {
      var raw = JSON.stringify({
        bId: this.agent.TeleToken.split(":")[0],
        VisitorAlias: this.context.midHeaderoperating,
      });

      var requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://us-central1-livechat-production.cloudfunctions.net/telegram/end_chat",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } else if (this.context.currentVisitor.current_pageUrl == "Whatsapp") {
      let raw = JSON.stringify({
        bId:
          this.agent.whatsapp && this.agent.whatsapp.whatsappAccessId
            ? this.agent.whatsapp.whatsappAccessId
            : "",
        VisitorAlias: this.context.midHeaderoperating,
        visitorId: chatID,
      });

      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://us-central1-livechat-production.cloudfunctions.net/whatsapp/end_chat",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } else if (this.context.currentVisitor.current_pageUrl == "metaFB") {
      var raw = JSON.stringify({
        bId: this.context.currentVisitor.IPv4,
        VisitorAlias: this.context.midHeaderoperating,
        chatId: chatID,
      });

      var requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };
      // fetch ("http://localhost:6790/livechat-production/us-central1/metaFb/end_chat", requestOptions)
      fetch(
        "https://us-central1-livechat-production.cloudfunctions.net/metaFb/end_chat",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }

    try {
      // to show confirmation dialog on ticketing
      if (
        this.context.rightSectionItemToShow === "ticketing" &&
        closeTicketing !== "closeTicketing"
      ) {
        // this.setState({ showDeskTicketPopUp: true });
        this.context.updateGlobalData("showDeskTicketPopUp", true);
        return false;
      }
    } catch (e) {}

    try {
      let callData = getCallStatus();
      if (callData.isCallStarted && callData.visitorId === chatID) {
        if (callData.calldirection === "INCOMING") {
          rejectCall();
        } else {
          endCall();
        }
      }
    } catch (errrrr) {}

    try {
      document.getElementById("respMobiMenu").classList.remove("d-none");
    } catch (err) {}

    let changeTextLangArr = this.context.changeTextLangArr
      ? this.context.changeTextLangArr
      : [];
    let liveChatDocId = this.context.liveChatDocId
      ? this.context.liveChatDocId
      : false;
    if (liveChatDocId) {
      delete changeTextLangArr[liveChatDocId];
    }
    this.context.updateGlobalData("changeTextLangArr", changeTextLangArr);

    const status2 = this.state.chatPickStatusTransaction.status
      ? this.state.chatPickStatusTransaction.status
      : "";
    const msgtoshow = this.state.chatPickStatusTransaction.msgToShow
      ? this.state.chatPickStatusTransaction.msgToShow
      : "";
    const fromVisitor2 = this.context.currentVisitor
      ? this.context.currentVisitor.fromVisitor
      : "";
    const isChatend = this.context.currentVisitor
      ? this.context.currentVisitor.isChatend
      : 0;

    try {
      document.getElementById("endChatShadow").style.display = "none";
      document.getElementById("chatMessageLoader").style.display = "block";
    } catch (error) {}

    var datarr = null;
    if (
      fromVisitor2 === "served" ||
      fromVisitor2 === "temp_send_sms_window" ||
      fromVisitor2 === "temp_send_chat_window"
    ) {
      datarr = [
        { key: "isStartChatClicked", value: chatID },
        { key: "clearSnapshotChatId", value: chatID },
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "closeVisitorId", value: chatID },
      ];
      this.context.updateGlobalData("array", datarr);
      this.setCurrentOngoingChat(chatID, "temp_send_sms_window");
    } else if (
      status2 === "close" &&
      msgtoshow.indexOf("left the site") !== -1
    ) {
      datarr = [
        { key: "isStartChatClicked", value: chatID },
        { key: "clearSnapshotChatId", value: chatID },
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "closeVisitorId", value: chatID },
      ];
      this.context.updateGlobalData("array", datarr);
      this.setCurrentOngoingChat(chatID, "end_chat");
    } else if (
      status2 === "close" &&
      msgtoshow.indexOf("picked by another agent") !== -1
    ) {
      datarr = [
        { key: "isStartChatClicked", value: chatID },
        { key: "clearSnapshotChatId", value: chatID },
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "closeVisitorId", value: chatID },
      ];
      this.context.updateGlobalData("array", datarr);
      this.setCurrentOngoingChat(chatID, "end_chat");
    } else if (
      status2 === "close" &&
      msgtoshow.indexOf("Something went wrong") !== -1
    ) {
      datarr = [
        { key: "isStartChatClicked", value: chatID },
        { key: "clearSnapshotChatId", value: chatID },
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "closeVisitorId", value: chatID },
      ];
      this.context.updateGlobalData("array", datarr);
      this.setCurrentOngoingChat(chatID, "end_chat");
    } else {
      datarr = [
        { key: "isStartChatClicked", value: chatID },
        { key: "clearSnapshotChatId", value: chatID },
        { key: "isDisableIncomingPanel", value: true },
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "closeVisitorId", value: chatID },
        { key: "endChatVisitorId", value: chatID },
      ];
      this.context.updateGlobalData("array", datarr);
      this.setCurrentOngoingChat(chatID, "end_chat");

			/* added by dharmesh */
			var notificationEmails = this.agent.notification_email ? this.agent.notification_email : [];
			const sentiment_analysis = this.agent.sentiment_analysis ? this.agent.sentiment_analysis : false;
			const opinion_analysis = this.agent.opinion_analysis ? this.agent.opinion_analysis : false;
			const index = notificationEmails.indexOf(this.agent.ownerEmail.toLowerCase());
			if (index > -1) {
				notificationEmails.splice(index, 1);
			}
			var body = [`ownerId=${this.agent.ownerId}&chatId=${chatID}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentProfile=${this.agent.profile_img}&curretStatus=${status2}&ownerEmail=${this.agent.ownerEmail.toLowerCase()}&notificationEmails=${JSON.stringify(notificationEmails)}&sentiment_analysis=${sentiment_analysis}&opinion_analysis=${opinion_analysis}`];
			fetch(GATEWAY_URL + "/endChatByAgent", {
				// fetch("https://us-central1-livechat-production.cloudfunctions.net/endChatByAgent", {
				method: "POST",
				body: body,
				headers: {
					"X-API-Key": FUNCTION_API_KEY,
					"Content-Type": "application/x-www-form-urlencoded",
					"Accept": "application/json",
					"Origin": window.location.origin
				}
			}).then(res => res.json())
				.then(response => {
					const status = response.result ? response.result : "";
					this.context.updateGlobalData("isDisableIncomingPanel", false);
					//// Delete data from Local Storage DB Start ////
					deleteChatData(chatID);
					deletePlanData(chatID);
					deleteChatCounter(chatID);
					deleteMessage(chatID)
					//// Delete data from Local Storage DB End ////
					try {
						document.getElementById('endChatShadow').style.display = 'block';
					} catch (eee) { }
					firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("tmpvisitors").doc(chatID).delete();
					if (status === "CHAT_ENDED_INACTIVITY" || status === "END_CHAT") {
						let dataObj = this.agent;
						dataObj.isChatend = isChatend;
						updateAgentChatData(dataObj, chatID, "close");
					}
				})
				.catch(e => {
					console.log(e, "ERROR");
					try {
						this.context.updateGlobalData("isDisableIncomingPanel", false);
						document.getElementById('endChatShadow').style.display = 'block';
					} catch (eee) { }
				});
		}
		activityLog("Ended chat");

		if(this.context.currentVisitor.current_pageUrl=="Whatsapp" && this.context.currentVisitor.message_to_show=="Chat ended due to inactivity" && this.context.currentVisitor.isChatend==1 ){
			this.autocloseWhatsAppChat(this.context.currentVisitor?.operating,this.context.currentVisitor?.id)
			}
	}
	autocloseWhatsAppChat= (id,chatId)=>{
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).get()
		.then(async(chatData)=>{
console.log(chatData.data().message_to_show,"------------",chatId);
			if(chatData.data().message_to_show=="Chat ended due to inactivity" && chatData.data().isChatend==1){
			firebaseServices.db.collection("Whatsapp_Integrations")
			.where('owner_id', '==', this.agent.ownerId)
			.limit(1)
			.get()
			.then((querySnapshot) => {

			  querySnapshot.forEach((doc) => {
			
			firebaseServices.db.collection("Whatsapp_Integrations")
			.doc(doc.id)
			.collection("Visitor")
			.doc(id + "")
			.get()
			.then((docSnapshot) => {
			  if (docSnapshot.exists && docSnapshot.data().visitorId === chatId) {
				firebaseServices.db.collection("Whatsapp_Integrations")
				  .doc(doc.id)
				  .collection("Visitor")
				  .doc(id + "")
				  .delete()
				  .then(() => {
					console.log("Visitor document deleted successfully.");
				  })
				  .catch((error) => {
					console.error("Error deleting visitor document: ", error);
				  });
			  } else {
				console.log("No matching visitor document found or visitorId doesn't match.");
			  }
			})
			.catch((error) => {
			  console.error("Error fetching visitor document: ", error);
			});
		  
		})      
		})
	}
   })    
	
}
	/* End Current Chat Function End */

  /* Leave Current Chat Function start */

  leaveChat = (chatID) => {
    if (this.context.goneOffline) {
      return false;
    }

    try {
      let callData = getCallStatus();
      if (callData.isCallStarted && callData.visitorId === chatID) {
        if (callData.calldirection === "INCOMING") {
          rejectCall();
        } else {
          endCall();
        }
      }
    } catch (errrrr) {}

    this.setCurrentOngoingChat(chatID, "leave_chat");
    var arr1 = [
      { key: "rightSectionItemToShow", value: "visitorslist" },
      { key: "closeVisitorId", value: chatID },
      { key: "isDisableIncomingPanel", value: true },
    ];
    this.context.updateGlobalData("array", arr1);
    try {
      document.getElementById("endChatShadow").style.display = "none";
      document.getElementById("chatMessageLoader").style.display = "block";
    } catch (error) {}

    if (this.state.isTyping) {
      this.state.isTyping = false;
    }

    const agentId = this.agent.agentId;
    const docRef = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("visitors")
      .doc(chatID);
    docRef.get().then((doc) => {
      if (doc.exists) {
        const _data = doc.data();
        const leaveagentIds = _data.agent_leave;
        if (leaveagentIds.indexOf(agentId) === -1) {
          leaveagentIds.push(agentId);
        }
        const agentids = _data.agent_ids;
        let allChatAgents = _data.agents;

        if (Object.keys(allChatAgents).indexOf(agentId) !== -1) {
          delete allChatAgents[agentId];
        }
        if (agentids !== null) {
          const agent_ids = this.itemRemove(agentids, agentId);
          docRef
            .update({
              agent_ids: agent_ids,
              agent_leave: leaveagentIds,
              agents: allChatAgents,
            })
            .then((res) => {
              var arr1 = [
                { key: "isStartChatClicked", value: chatID },
                { key: "isDisableIncomingPanel", value: false },
              ];
              this.context.updateGlobalData("array", arr1);
              ///this.setCurrentOngoingChat(chatID, "leave_chat");
            });
        } else {
          docRef
            .update({ agent_leave: leaveagentIds, agents: allChatAgents })
            .then((res) => {
              var arr1 = [
                { key: "isStartChatClicked", value: chatID },
                { key: "isDisableIncomingPanel", value: false },
              ];
              this.context.updateGlobalData("array", arr1);
              ///this.setCurrentOngoingChat(chatID, "leave_chat");
            });
        }

        firebaseServices.db
          .collection("users")
          .doc(this.agent.ownerId)
          .collection("visitors")
          .doc(chatID)
          .collection("agent_data")
          .doc(agentId)
          .update({ status: 2 });
        // firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(chatID).collection('typing_status').doc(this.agent.name).set({ isTyping: false });

        this.saveMessage(
          "Agent " + this.agent.name + " left the chat",
          7,
          "",
          "",
          chatID,
          0,
          "leavechat"
        );
      }
    });
    activityLog("Left chat");
  };

  itemRemove = (arrayData, itemId) => {
    let returnArrayData = arrayData;
    if (returnArrayData.length > 0) {
      if (returnArrayData.includes(itemId)) {
        var index = returnArrayData.indexOf(itemId);
        if (index !== -1) {
          returnArrayData.splice(index, 1);
        }
      }
    }
    return returnArrayData;
  };

  /* Leave Current Chat Function End */

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    if (
      this.context.isCloseBtnClicked === "close" ||
      this.context.isCloseBtnClicked === "end"
    ) {
      return "EndAndCloseChat";
    } else if (this.context.isCloseBtnClicked === "leave") {
      return "LeaveChat";
    }

    if (Object.keys(this.context.chatPickStatusTransaction).length > 0) {
      if (
        this.context.chatPickStatusTransaction.key !==
          this.state.chatPickStatusTransaction.key ||
        this.context.chatPickStatusTransaction.status !==
          this.state.chatPickStatusTransaction.status
      ) {
        return "reload";
      }
    }

    if (this.context.whatsappmsg !== "") {
      let tmpWhatsapmsg = this.context.whatsappmsg;
      this.context.updateGlobalData("whatsappmsg", "");
      this.setState({ chatTypes: "whatsapp" }, () => {
        try {
          autosize(document.getElementById("txtWhatsapp"));
          document.getElementById("txtWhatsapp").value = tmpWhatsapmsg;
          document.getElementById("txtWhatsapp").focus();
          this.update(document.getElementById("txtWhatsapp"));
        } catch (errrr) {}
      });
    }

    return false;
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    var arrayToSet = null;
    if (snapshot === "EndAndCloseChat") {
      arrayToSet = [
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "isCloseBtnClicked", value: "" },
      ];
      this.context.updateGlobalData("array", arrayToSet);
      this.endChat(this.context.liveChatDocId);
      if (this.state.showCannedResp) {
        this.setState({
          showCannedResp: false,
          cannedList: [],
        });
      }
    } else if (snapshot === "LeaveChat") {
      arrayToSet = [
        { key: "rightSectionItemToShow", value: "visitorslist" },
        { key: "isCloseBtnClicked", value: "" },
      ];
      this.context.updateGlobalData("array", arrayToSet);
      this.leaveChat(this.context.liveChatDocId);
      if (this.state.showCannedResp) {
        this.setState({
          showCannedResp: false,
          cannedList: [],
        });
      }
    }

    if (snapshot === "reload") {
      /* For dynamic input id start */
      let elementId = "msg";
      if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "incoming_tdm"
      ) {
        elementId = "txtTwitterDM";
      } else if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "incoming_fb"
      ) {
        elementId = "txtMessanger";
      } else if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "incoming_insta"
      ) {
        elementId = "txtMessanger";
      } else if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "incoming_whatsapp"
      ) {
        elementId = "txtWhatsapp";
      } else if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "incoming_sms"
      ) {
        elementId = "txtSms";
      } else if (
        this.context.currentVisitor &&
        this.context.currentVisitor.start_url === "outgoing_sms"
      ) {
        elementId = "txtSms";
      }

      this.setState(
        { chatPickStatusTransaction: this.context.chatPickStatusTransaction },
        () => {
          if (this.context.chatPickStatusTransaction.status !== "chatting") {
            if (this.state.chatTypes === "chat") {
              this.isEditorLoaded = false;
            }
            return false;
          }

          try {
            let elemById;

            /* For Smart compose Start */
            if (this.state.chatTypes === "chat") {
              this.doComponentMount();
              // elemById = document.getElementById(this.state.editorId);
              elemById = document.getElementById("msg");
            } else {
              elemById = document.getElementById(elementId);
            }
            /* For Smart compose End */

            autosize(elemById);
            var tmpMsg = this.state.chatMessages[this.context.liveChatDocId]
              ? this.state.chatMessages[this.context.liveChatDocId]
              : "";
            tmpMsg = tmpMsg.trim();
            if (this.state.chatTypes === "chat") {
              elemById.innerText = tmpMsg; // Bug fix: Message of one client showing in other
              // elemById.value = tmpMsg;
            } else {
              elemById.value = tmpMsg;
            }
            elemById.focus();
            this.update(elemById);

            if (Object.keys(this.state.filesPreviewChats).length > 0) {
              let attachemnts =
                this.state.filesPreviewChats[this.context.liveChatDocId];
              if (
                attachemnts === undefined ||
                typeof attachemnts === "undefined"
              ) {
                var btnClicked = this.sendButtonClickedIdes.indexOf(
                  this.context.liveChatDocId
                );
                if (btnClicked !== -1) {
                  this.sendButtonClickedIdes.splice(btnClicked, 1);
                }
                document.getElementById("App").style.display = "none";
                document.getElementById("fileUploader").value = "";
              } else if (attachemnts.length > 0) {
                var allAttachemnts = attachemnts.filter((file) => {
                  return file === "" ? false : true;
                });
                if (
                  this.sendButtonClickedIdes.indexOf(
                    this.context.liveChatDocId
                  ) !== -1
                ) {
                  allAttachemnts.forEach((val, index) => {
                    var attIndex = val.key.replace("file_", "");
                    document
                      .getElementById(
                        "uploading_" +
                          attIndex +
                          "_" +
                          this.context.liveChatDocId
                      )
                      .classList.add("uploading");
                    document.getElementById(
                      "delete_" + attIndex + "_" + this.context.liveChatDocId
                    ).style.display = "none";
                  });
                } else {
                  allAttachemnts.forEach((val, index) => {
                    var attIndex = val.key.replace("file_", "");
                    document
                      .getElementById(
                        "uploading_" +
                          attIndex +
                          "_" +
                          this.context.liveChatDocId
                      )
                      .classList.remove("uploading");
                    document.getElementById(
                      "delete_" + attIndex + "_" + this.context.liveChatDocId
                    ).style.display = "block";
                  });
                }
                document.getElementById("dropZoneSection").style.display =
                  "none";
                document.getElementById("App").style.display = "block";
              } else {
                var btnClicked2 = this.sendButtonClickedIdes.indexOf(
                  this.context.liveChatDocId
                );
                if (btnClicked2 !== -1) {
                  this.sendButtonClickedIdes.splice(btnClicked2, 1);
                }
                document.getElementById("App").style.display = "none";
                document.getElementById("fileUploader").value = "";
              }
            } else {
              var btnClicked3 = this.sendButtonClickedIdes.indexOf(
                this.context.liveChatDocId
              );
              if (btnClicked3 !== -1) {
                this.sendButtonClickedIdes.splice(btnClicked3, 1);
              }
              document.getElementById("App").style.display = "none";
              document.getElementById("fileUploader").value = "";
            }
          } catch (err) {
            if (this.state.chatTypes === "chat") {
              /* For Smart compose Start */
              this.doComponentMount();
              /* For Smart compose End */
            }
          }
        }
      );
    }

    if (
      Object.keys(this.context.currentVisitor).length > 0 &&
      this.state.chatPickStatusTransaction.status === "serving"
    ) {
      if (
        this.context.currentVisitor.agent_ids.length !==
          this.state.groupChatAgents.length ||
        this.state.liveChatDocId !== this.context.liveChatDocId
      ) {
        this.state.liveChatDocId = this.context.liveChatDocId;
        this.getGroupChatAgents();
      }
    } else {
      this.state.groupChatAgents = [];
    }

    if (Object.keys(this.context.currentVisitor).length > 0) {
      if (
        this.state.liveChatDocId2 !== this.context.liveChatDocId ||
        this.context.startClickedChatId === this.state.liveChatDocId2
      ) {
        this.state.liveChatDocId2 = this.context.liveChatDocId;
        this.state.clientPlan = false;
        if (this.context.startClickedChatId === this.state.liveChatDocId2) {
          setTimeout(async () => {
            let clientPlan = await getPlanData(this.context.liveChatDocId);
            this.setState({ clientPlan: clientPlan, chatTypes: "chat" });
          }, 3000);
        } else {
          let clientPlan = await getPlanData(this.context.liveChatDocId);
          this.setState({ clientPlan: clientPlan, chatTypes: "chat" });
        }
      }
    }

    if (
      this.state.chatPickStatusTransaction.status === "close" &&
      this.state.showCannedResp
    ) {
      this.setState({
        showCannedResp: false,
        cannedList: [],
      });
    }
  };

  setCurrentOngoingChat = (chatID, type = "") => {
    if (this.closeIdes.indexOf(chatID) === -1) {
      this.closeIdes.push(chatID);
    }

    var tmpAllIdes = this.context.ongoingChatIdes;
    var allIdes = tmpAllIdes.filter((vId) => {
      return this.closeIdes.indexOf(vId) === -1 ? true : false;
    });

    var arrayToSet = [];
    var Obj1 = {
      key: "currentVisitor",
      value: {},
    };

    var Obj2 = {
      key: "midHeadercountry",
      value: "NOT_AVAILABLE",
    };

    var Obj3 = {
      key: "midHeaderbrowser",
      value: "",
    };

    var Obj4 = {
      key: "midHeaderoperating",
      value: "",
    };

    var Obj5 = {
      key: "chatPickStatusTransaction",
      value: { key: "", status: "", Text: "", msgToShow: null },
    };

    var Obj6 = {
      key: "showMidHeader",
      value: false,
    };

    var Obj7 = {
      key: "liveChatDocId",
      value: "",
    };

    var Obj8 = {
      key: "ongoingChatIdes",
      value: allIdes,
    };

    arrayToSet.push(Obj8);
    arrayToSet.push(Obj1);
    arrayToSet.push(Obj2);
    arrayToSet.push(Obj3);
    arrayToSet.push(Obj4);
    arrayToSet.push(Obj5);
    arrayToSet.push(Obj6);
    arrayToSet.push(Obj7);

    var objLeave = null;
    if (type === "end_chat" || type === "leave_chat") {
      if (Object.keys(this.closedVisitor).length > 0 && type === "end_chat") {
        if (
          this.closedVisitor.incoming === 1 ||
          this.closedVisitor.visitor_status === "incoming"
        ) {
          objLeave = {
            key: "blankChatScreenMsg",
            value:
              "You've closed inactive chat window . You can either select another ongoing chat or select an incoming chat",
          };
          arrayToSet.push(objLeave);
        } else {
          objLeave = {
            key: "blankChatScreenMsg",
            value:
              "You've ended an ongoing chat. You can either select another ongoing chat or select an incoming chat",
          };
          arrayToSet.push(objLeave);
        }
        this.closedVisitor = {};
      } else {
        objLeave = {
          key: "blankChatScreenMsg",
          value:
            "You've ended an ongoing chat. You can either select another ongoing chat or select an incoming chat",
        };
        arrayToSet.push(objLeave);
      }
    } else if (type === "temp_send_sms_window") {
      objLeave = {
        key: "blankChatScreenMsg",
        value:
          "No ongoing chat is assigned to you. Select an incoming chat or initiate a chat by clicking on a visitor from visitor panel.",
      };
      arrayToSet.push(objLeave);
    } else {
      objLeave = {
        key: "blankChatScreenMsg",
        value:
          "You've ended an ongoing chat. You can either select another ongoing chat or select an incoming chat",
      };
      arrayToSet.push(objLeave);
    }

    this.context.updateGlobalData("array", arrayToSet);

    setTimeout(() => {
      try {
        document.getElementById("chatMessageLoader").style.display = "none";
      } catch (err2) {}
    }, 2000);
  };

  /* For Autoresize textarea start */

  getParentOverflows = (el) => {
    var arr = [];
    while (el && el.parentNode && el.parentNode instanceof Element) {
      if (el.parentNode.scrollTop) {
        arr.push({
          node: el.parentNode,
          scrollTop: el.parentNode.scrollTop,
        });
      }
      el = el.parentNode;
    }
    return arr;
  };

  resize = (ta) => {
    try {
      var heightOffset = null;
      //var clientWidth = null;

      var style = window.getComputedStyle(ta, null);

      if (style.resize === "vertical") {
        ta.style.resize = "none";
      } else if (style.resize === "both") {
        ta.style.resize = "horizontal";
      }

      if (style.boxSizing === "content-box") {
        heightOffset = -(
          parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)
        );
      } else {
        heightOffset =
          parseFloat(style.borderTopWidth) +
          parseFloat(style.borderBottomWidth);
      }
      // Fix when a textarea is not on document body and heightOffset is Not a Number
      if (isNaN(heightOffset)) {
        heightOffset = 0;
      }

      if (ta.scrollHeight === 0) {
        // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
        return;
      }

      var overflows = this.getParentOverflows(ta);
      var docTop =
        document.documentElement && document.documentElement.scrollTop; // Needed for Mobile IE (ticket #240)

      ta.style.height = "";
      ta.style.height = ta.scrollHeight + heightOffset + "px";

      // used to check if an update is actually necessary on window.resize
      //clientWidth = ta.clientWidth;

      // prevents scroll-position jumping
      overflows.forEach(function (el) {
        el.node.scrollTop = el.scrollTop;
      });

      if (docTop) {
        document.documentElement.scrollTop = docTop;
      }
    } catch (err) {}
  };

  update = (ta) => {
    this.resize(ta);
    var styleHeight = Math.round(parseFloat(ta.style.height));
    var computed = window.getComputedStyle(ta, null);
    var cachedHeight = null;
    // Using offsetHeight as a replacement for computed.height in IE, because IE does not account use of border-box
    var actualHeight =
      computed.boxSizing === "content-box"
        ? Math.round(parseFloat(computed.height))
        : ta.offsetHeight;

    // The actual height not matching the style height (set via the resize method) indicates that
    // the max-height has been exceeded, in which case the overflow should be allowed.
    if (actualHeight < styleHeight) {
      if (computed.overflowY === "hidden") {
        ta.style.overflowY = "scroll";
        this.resize(ta);
        actualHeight =
          computed.boxSizing === "content-box"
            ? Math.round(parseFloat(window.getComputedStyle(ta, null).height))
            : ta.offsetHeight;
      }
    } else {
      // Normally keep overflow set to hidden, to avoid flash of scrollbar as the textarea expands.
      if (computed.overflowY !== "hidden") {
        ta.style.overflowY = "hidden";
        this.resize(ta);
        actualHeight =
          computed.boxSizing === "content-box"
            ? Math.round(parseFloat(window.getComputedStyle(ta, null).height))
            : ta.offsetHeight;
      }
    }

    if (cachedHeight !== actualHeight) {
      cachedHeight = actualHeight;
      var evt = new Event("autosize:resized", { bubbles: true });
      try {
        ta.dispatchEvent(evt);
      } catch (err) {}
    }
  };

  /* For Autoresize textarea End */

  /* For Served visitors Start */

  componentDidMount = async () => {
    var uRole = await getUserRole(this.agent.role);
    this.userRole = uRole.toLowerCase();
    this.fetchWhatsAppTemplates();
  };
  fetchWhatsAppTemplates = async () => {
    try {
      const response = await fetch(
        "https://us-central1-livechat-production.cloudfunctions.net/twilio/listtemplates"
      );
      if (!response.ok) {
        this.setState({
          whatsAppTemplates: [
            {
              lancode: "English",
              id: "1",
              content: "Not found",
              title: "Empty",
            },
          ],
        });
      } else {
        const data = await response.json();
        this.setState({ whatsAppTemplates: data.templates });
      }
    } catch (error) {
      console.error("Error fetching emoji options:", error);
    }
  };
  handleTemplateSelection = (id) => {
    const selectedValue = this.state.whatsAppTemplates.find(
      (template) => template.id === id
    );
    let selectedValueText = selectedValue.content;
    selectedValueText = selectedValueText.replace(
      "{{1}}",
      this.agent.agent_name
    );
    autosize(document.getElementById("txtWhatsapp"));
    document.getElementById("txtWhatsapp").value = selectedValueText;
    document.getElementById("txtWhatsapp").focus();
    this.update(document.getElementById("txtWhatsapp"));

    this.setState({ whatsAppTemplatesSelected: selectedValue });
    this.setState({ whatsAppTemplatesPreviewText: selectedValueText });
  };
  joinChat = (chatId) => {
    if (this.context.goneOffline) {
      return false;
    }

    this.setState({ startBtnText: "Starting..." });
    this.context.updateGlobalData("isDisableIncomingPanel", true);
    var agentProfile = encodeURIComponent(this.agent.profile_img);
    var body = [
      `ownerId=${this.agent.ownerId}&chatId=${chatId}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentShort=${this.agent.agent_short}&fullName=${this.agent.agent_name}&profileimg=${agentProfile}&email=${this.agent.email}&canJoin=yes`,
    ];
    fetch(MAIL_FUNCTION_URL + "/startChatByAgent", {
      method: "post",
      body: body,
      headers: {
        //"X-API-Key" : FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const status = response.status ? response.status : "";
        setTimeout(() => {
          this.setState({ startBtnText: "" });
        }, 500);
        if (status === "YOU_PICKED_CHAT") {
          this.setState({
            chatPickStatusTransaction: {
              key: chatId,
              status: "chatting",
              Text: "Continue",
              msgToShow: null,
            },
          });
          var array1 = [
            {
              key: "chatPickStatusTransaction",
              value: {
                key: chatId,
                status: "chatting",
                Text: "Continue",
                msgToShow: null,
              },
            },
            { key: "closeVisitorId", value: chatId },
            { key: "rightSectionItemToShow", value: "visitorslist" },
            { key: "isDisableIncomingPanel", value: false },
            { key: "isStartChatClicked", value: chatId },
          ];

          var curVisitor = this.context.currentVisitor;
          curVisitor.visitor_status = "ongoing";
          curVisitor.incoming = 2;
          array1.push({ key: "currentVisitor", value: curVisitor });
          array1.push({ key: "isStartChat", value: false });
          this.context.updateGlobalData("array", array1);
          try {
            document.getElementById("msg").focus();
          } catch (err) {
            console.log(err);
          }
          firebaseServices.db
            .collection("users")
            .doc(this.agent.ownerId)
            .collection("visitors")
            .doc(chatId)
            .collection("agent_data")
            .doc(this.agent.agentId)
            .set({
              addedon: new Date().getTime() + this.state.offset,
              agent_added_by: this.agent.agentId,
              agent_added_by_name: this.agent.name,
              agent_id: this.agent.agentId,
              agent_name: this.agent.name,
              agent_profile_img: this.agent.profile_img,
              status: 1,
              agent_ref: firebaseServices.db
                .collection("users")
                .doc(this.agent.ownerId)
                .collection("agents")
                .doc(this.agent.agentId),
              calling_status: this.agent.calling_status
                ? this.agent.calling_status
                : 0,
            });
        } else {
          var array44 = [
            {
              key: "chatPickStatusTransaction",
              value: {
                key: chatId,
                status: "close",
                Text: "Close window",
                msgToShow: "Something went wrong.",
              },
            },
            { key: "isDisableIncomingPanel", value: false },
          ];
          this.context.updateGlobalData("array", array44);
          this.setState({
            chatPickStatusTransaction: {
              key: chatId,
              status: "close",
              Text: "Close window",
              msgToShow: "Something went wrong.",
            },
          });
        }
      })
      .catch((e) => {
        this.context.updateGlobalData("isDisableIncomingPanel", false);
        this.setState({ startBtnText: "" });
        //console.log(e, "ERROR");
      });
    activityLog("Joined chat");
  };

  getGroupChatAgents = () => {
    let chatPickStatusTransaction = this.state.chatPickStatusTransaction;
    if (chatPickStatusTransaction.status === "serving") {
      try {
        let allChatAgents = this.context.currentVisitor.agents
          ? this.context.currentVisitor.agents
          : {};
        if (Object.keys(allChatAgents).length > 0) {
          var allAgents = [];
          for (const key in allChatAgents) {
            let agent = allChatAgents[key];
            allAgents.push({ name: agent.name });
          }
          this.setState({ groupChatAgents: allAgents });
        }
      } catch (err) {}
    }
  };

  /* For Served visitors End */

  /* For Chat/SMS start */

  handleChatType = (type) => {
    if (this.state.chatTypes === type) {
      return false;
    }

    this.setState({ chatTypes: type }, () => {
      try {
        if (type === "sms") {
          autosize(document.getElementById("txtSms"));
          document.getElementById("txtSms").value = "";
          document.getElementById("txtSms").focus();
          this.update(document.getElementById("txtSms"));
        } else if (type === "whatsapp" || type === "whatsapptemplate") {
          autosize(document.getElementById("txtWhatsapp"));
          document.getElementById("txtWhatsapp").value = "";
          document.getElementById("txtWhatsapp").focus();
          this.update(document.getElementById("txtWhatsapp"));
        } else if (type === "chat") {
          /* autosize(document.getElementById("msg"));
					document.getElementById("msg").value = "";
					document.getElementById("msg").focus();
					this.update(document.getElementById("msg")); */
          setTimeout(() => {
            /* For Smart compose Start */
            this.isEditorLoaded = false;
            this.doComponentMount();
            /* For Smart compose End */
            //autosize(document.getElementById(this.state.editorId));
            // document.getElementById(this.state.editorId).innerText = "";
            // document.getElementById(this.state.editorId).focus();
            document.getElementById("msg").innerText = "";
            document.getElementById("msg").focus();
            //this.update(document.getElementById(this.state.editorId));
          }, 50);
        }
      } catch (errr) {}
    });
  };

  /* For Chat/SMS End */

  /* For Whatsapp start */

  checkWhatsappEnter = (event) => {
    if (!this.checkResizeRunWApp) {
      this.checkResizeRunWApp = true;
      autosize(event.target);
    }

    if (event.target.value.trim() !== "") {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.target.style.height = "46px";
          event.preventDefault();
          if (this.state.chatTypes === "whatsapptemplate") {
            this.sendWhatsAppAgentTemplate();
          } else {
            this.sendWhatsappMessage();
          }
          return;
        } else {
          autosize(event.target);
        }
      }
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (this.state.chatTypes === "whatsapptemplate") {
          this.sendWhatsAppAgentTemplate();
        } else {
          this.sendWhatsappMessage();
        }
      }
    }
  };

  sendWhatsappMessage = async () => {
    var message = document.getElementById("txtWhatsapp").value;
    message = message.trim();
    if (!this.context.currentVisitor.client_phone) {
      //|| !this.agent.whatsapp_msg)
      console.log("Error in data!");
      return false;
    }

    this.closeEmojis();

    let chatId = this.context.liveChatDocId;
    const tmpCurVisitor = this.context.currentVisitor
      ? this.context.currentVisitor
      : {};

    // For Draft massages Start //////
    try {
      let tmpMsgObj = this.state.chatMessages ? this.state.chatMessages : {};
      if (tmpMsgObj.hasOwnProperty(chatId)) {
        delete tmpMsgObj[chatId];
        this.setState({ chatMessages: tmpMsgObj });
      }
    } catch (err) {}
    // For Draft massages End //////

    let filesToBeSentChats = this.state.filesToBeSentChats;
    if (filesToBeSentChats[chatId]) {
      if (filesToBeSentChats[chatId].length > 0) {
        if (this.sendButtonClickedIdes.indexOf(chatId) === -1) {
          this.sendButtonClickedIdes.push(chatId);
        }
        this.context.updateGlobalData("isDisableIncomingPanel", true);
        this.startThirdPartyUploadImage(
          filesToBeSentChats[chatId],
          chatId,
          "whatsapp"
        );
        filesToBeSentChats[chatId] = [];
        this.setState({ filesToBeSentChats }, () => {
          setTimeout(() => {
            this.context.updateGlobalData("isDisableIncomingPanel", false);
          }, 500);
        });
      }
    }

    if (message) {
      /* Save WhatsApp as message start */
      var agentProfile = encodeURIComponent(this.agent.profile_img);
      var tmpmsgContent = encodeURIComponent(message);
      var body = [
        `ownerId=${this.agent.ownerId}&chatId=${
          this.context.liveChatDocId
        }&agentId=${this.agent.agentId}&agentName=${
          this.agent.name
        }&agentProfile=${agentProfile}&agentShort=${nameIntials(
          this.agent.name
        )}&msg=${tmpmsgContent}&msg_type=13&msg_url=whatsapp&contenttype=&filesize=0&action=addmessage`,
      ];
      fetch(GATEWAY_URL + "/saveMessage", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.result === "success") {
            let chatData = {
              msgId: response.chatId,
              ownerId: this.agent.ownerId,
              chatId: this.context.liveChatDocId,
              message: tmpmsgContent,
              agentId: this.agent.agentId,
            };
            let serverTimestamp22 = await getDateInUTC();
            let userDetailsNew = localStorage.getItem("agent")
              ? JSON.parse(localStorage.getItem("agent"))
              : {};

            firebaseServices.db
              .collection("users")
              .doc(this.agent.ownerId)
              .collection("visitors")
              .doc(chatData.chatId)
              .update({ updatedon: serverTimestamp22, latest_msg: message });

            // Whatsapp Message code starts through whatsapp360 connectIntegration
            if (
              userDetailsNew &&
              (userDetailsNew.whatsappbusinessmessenger_status ||
                userDetailsNew.whatsappbusinessmessenger_status === 1)
            ) {
              let changeTextLangArr = this.context.changeTextLangArr
                ? this.context.changeTextLangArr
                : {};
              let lang_code = "";
              if (changeTextLangArr.hasOwnProperty(chatId)) {
                lang_code = changeTextLangArr[chatId];
              }
              let hookUrl = userDetailsNew.hookUrl
                ? userDetailsNew.hookUrl
                : "";
              if (hookUrl === "") {
                hookUrl = await this.getHookUrl(
                  userDetailsNew.ownerId,
                  "whatsapp_360"
                );
                userDetailsNew.hookUrl = hookUrl;
                localStorage.setItem("agent", JSON.stringify(userDetailsNew));
              }
              let body22 = [
                `recipient_id=${encodeURIComponent(
                  this.context.currentVisitor.client_phone
                )}&sender_id=${"ownerMobileNumber"}&message=${tmpmsgContent}&msgId=${encodeURIComponent(
                  chatData.msgId
                )}&ownerId=${encodeURIComponent(
                  chatData.ownerId
                )}&chatId=${encodeURIComponent(
                  chatData.chatId
                )}&agentId=${encodeURIComponent(
                  chatData.agentId
                )}&page_token=&lang_code=${lang_code}&start_url=${
                  this.context.currentVisitor.start_url
                }&hookUrl=${hookUrl}`,
              ];
              //let sendMessageUrl = `${MAIL_FUNCTION_URL}/connectIntegration/sendmessage`;
              let sendMessageUrl = `${GATEWAY_URL}/social_sendmessage`;
              let whatsappHeaders = {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Origin: window.location.origin,
              };

              fetch(sendMessageUrl, {
                method: "post",
                body: body22,
                headers: whatsappHeaders,
              })
                .then((res) => {
                  return res.json();
                })
                .then(async (response) => {
                  // console.log("\n\n\n💡🛠 -> file: footer.js -> line 3756 -> response", response);
                });
              // Whatsapp Message code ends through whatsapp360
            } else {
              // Whatsapp Message code starts through twilio
              sendWhatsAppMessage(this.context.currentVisitor, chatData);
            }
            /* Check first message time start */
            if (this.firstRespChatArray.indexOf(chatId) === -1) {
              this.firstRespChatArray.push(chatId);
              let datObj = this.agent;
              datObj.currentVisitor = tmpCurVisitor;
              updateAgentChatData(datObj, chatId, "first_msg_response");
            }
            /* Check first message time end */
            // whatsapp message code ends
          }
        })
        .catch((err) => {});
      /* Save WhatsAp as message End  */
    }

    try {
      document.getElementById("txtWhatsapp").value = "";
      document.getElementById("txtWhatsapp").style.height = "46px";
    } catch (err) {}
  };

  /* For Whatsapp End */

  endGuideTour = (docId, context) => {
    let userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : false;
    if (userDetails === false) {
      return;
    }
    firebaseServices.db
      .collection("users")
      .doc(userDetails.ownerId)
      .collection("onboarding_guide")
      .doc(docId)
      .update({
        status: true,
      });
    this.context.updateGlobalData(context, true);
  };

  guidenextStep = (docId, step, context) => {
    let userDetails = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : false;
    if (userDetails === false) {
      return;
    }
    firebaseServices.db
      .collection("users")
      .doc(userDetails.ownerId)
      .collection("onboarding_guide")
      .doc(docId)
      .update({
        step: step,
      });
    this.context.updateGlobalData(context, step);
  };

  //* logic for dropdown list for canned text
  dropdownListCannedText(objects, eventKeyCode) {
    var inp = document.getElementById("msg");

    //* if space key is pressed or canned text data is of zero length
    if (objects.length === 0 || eventKeyCode === 32) {
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      console.log("No canned text found...");
      //* removing event listener
      inp.removeEventListener("keydown", keydownEventListener);
      this.cannedTextDetectedFlag = false;
      return false;
    } else {
      var self = this; // passing the reference
      var currentFocus = -1;
      let a,
        b,
        val = this.state.searchText; // inp.value;

      /*close any already open lists of autocompleted values*/
      closeAllLists();

      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", inp.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      inp.parentNode.appendChild(a);

      //* if searchText input is empty, show all items of list
      if (this.state.searchText === "") {
        for (const obj of objects) {
          addChildNodesToDiv(a, b, val, obj, inp);
        }
      }
      //* if searchText input is not empty, show matching items of list
      else {
        for (const obj of objects) {
          /*check if the item starts with the same letters as the text field value:*/
          if (
            obj.canned_hashtag.substr(0, val.length).toUpperCase() ===
            val.toUpperCase()
          ) {
            addChildNodesToDiv(a, b, val, obj, inp);
          }
        }
      }
      //* execute a function presses a key on the keyboard:
      inp.addEventListener("keydown", keydownEventListener);
      /*execute a function when someone clicks in the document:*/
      document.addEventListener("click", function (e) {
        closeAllLists(e.target);
      });
      return true;
    }

    //* fuunction to add child nodes to div of dropdown list
    function addChildNodesToDiv(a, b, val, obj, inp) {
      b = document.createElement("DIV");
      /*make the matching letters bold:*/
      b.innerHTML = "<strong>/" + obj.canned_hashtag + " </strong>";
      if (obj.canned_text.length < 30) b.innerHTML += obj.canned_text;
      else b.innerHTML += obj.canned_text.slice(0, 20) + " ..."; //* maximum length to be shown in dropdown list
      /*insert a input field that will hold the current array item's value:*/
      b.innerHTML += `<input type="hidden" value="${encodeURIComponent(
        obj.canned_text
      )}">`;
      /*execute a function when someone clicks on the item value (DIV element):*/
      b.addEventListener("click", function (e) {
        //* insert the value for the autocomplete text field:
        const element = document.getElementById("msg");
        const msgValue = element.innerHTML;
        let startIndex = msgValue.search("/" + self.state.searchText);
        // console.log("💡🛠 -> file: footer.js -> line 4014 -> startIndex initial check:", startIndex);
        try {
          if (self.state.searchText === "") {
            let indexList = [...msgValue.matchAll(new RegExp("/", "gi"))].map(
              (a) => a.index
            ); // to find the '/' in the sentence
            if (indexList.length > 1) {
              let tempMsgValue = self.state.currentChildNode;
              // console.log("💡🛠 -> file: footer.js -> line 4022 -> tempMsgValue", tempMsgValue);
              if (
                tempMsgValue[self.selectionStart + 1] === undefined ||
                tempMsgValue[self.selectionStart - 1] === undefined
              ) {
                startIndex = indexList[indexList.length - 1];
              } else {
                let fwdBckChar = tempMsgValue.slice(
                  self.selectionStart - 2,
                  self.selectionStart + 1
                );
                startIndex = msgValue.search(fwdBckChar) + 1;
                // startIndex = self.selectionStart - 1 + (indexListNbsp.length*6); // indexList[indexList.length - 1]
                // startIndex = indexList[indexList.length - 1]
              }
            }
          }
          // console.log("💡🛠 -> file: footer.js -> line 4014 -> startIndex final check:", startIndex);
        } catch (error) {
          console.log("Error: " + error);
          startIndex = msgValue.length - 1;
        }

        // get child node location from
        var indexChild = 0;
        // logic to get index of current child node
        var children = element.childNodes;
        // console.log("💡🛠 || file: footer.js || line 3783 || children", children);
        // console.log("💡🛠 || file: footer.js || line 2201 || self.state.currentChildNode", self.state.currentChildNode);

        for (let i = 0; i < children.length; i++) {
          if (self.state.currentChildNode === children[i].data) {
            indexChild = i;
          }
        }

        let canned_text = decodeURIComponent(
          this.getElementsByTagName("input")[0].value.trim()
        );
        console.log(`Canned Text Result-->"${canned_text}"`);
        let endIndex = startIndex + val.length + 1;
        // console.log("💡🛠 -> file: footer.js -> line 3962 -> startIndex", startIndex);
        // console.log("💡🛠 -> file: footer.js -> line 3991 -> endIndex", endIndex);
        let replaceVal =
          msgValue.substring(0, startIndex) +
          canned_text +
          msgValue.substring(endIndex);
        // console.log("💡🛠 -> file: footer.js -> line 3795 -> replaceVal", replaceVal);

        element.innerHTML = replaceVal;

        let setPosition =
          self.selectionEnd +
          canned_text.length -
          self.state.searchTextLength -
          1;
        // let el = document.getElementById("msg");
        let range = document.createRange();
        let sel = window.getSelection();
        try {
          if (setPosition <= element.innerText.length) {
            range.setStart(element.childNodes[indexChild], setPosition);
          } else {
            range.setStart(element.childNodes[0], element.innerText.length);
          }
        } catch (error) {
          console.log("ERROR: ", error);
          range.setStart(
            element.childNodes[element.childNodes.length - 1],
            element.childNodes[element.childNodes.length - 1].length - 1
          ); // todo
        }
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        // close the list of autocompleted values
        closeAllLists();
        autosize(element);
        element.focus();
        self.previousSearchText = (Math.random() + 1).toString(36).substring(2);
        self.currentCursorPos = self.getCaretPositionCanned();
        self.update(element);
        self.cannedTextInfoFlag = "replaced";
        //* removing event listener
        inp.removeEventListener("keydown", keydownEventListener);
      });
      a.appendChild(b);
    }

    //* function for logic to add keydown event listener for dropdown items
    function keydownEventListener(e) {
      // if key pressed is not backspace, enter, up, down, slash(/)
      if (
        e.keyCode !== 8 &&
        e.keyCode !== 40 &&
        e.keyCode !== 38 &&
        e.keyCode !== 13 &&
        e.keyCode !== 191
      ) {
        currentFocus = -1;
        //* removing event listener
        inp.removeEventListener("keydown", keydownEventListener);
      }

      if (self.state.searchText.length === 0 && e.keyCode === 8) {
        // if length is zero & backspace pressed
        self.cannedTextDetectedFlag = false;
        currentFocus = -1;
        self.previousSearchText = (Math.random() + 1).toString(36).substring(2);
        closeAllLists();
        //* removing event listener
        inp.removeEventListener("keydown", keydownEventListener);
      } else if (objects.length > 0) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        // console.log("💡🛠 -> file: footer.js -> line 3960 -> currentFocus", currentFocus);
        if (e.keyCode === 40) {
          /*If the arrow DOWN key is pressed,
					increase the currentFocus variable:*/
          currentFocus++;
          /*and and make the current item more visible:*/
          addActive(x);
        } else if (e.keyCode === 38) {
          //up
          /*If the arrow UP key is pressed,
					decrease the currentFocus variable:*/
          currentFocus--;
          /*and and make the current item more visible:*/
          addActive(x);
        } else if (e.keyCode === 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          if (currentFocus > -1) {
            e.preventDefault();
            /*and simulate a click on the "active" item:*/
            if (x) {
              // if enter is pressed and choice is valid
              x[currentFocus].click();
              currentFocus = -1;
              self.previousSearchText = (Math.random() + 1)
                .toString(36)
                .substring(2);
              closeAllLists();
            }
          } else {
            // if enter is presed but no choice selected
            currentFocus = -1;
            self.cannedTextDetectedFlag = false;
            self.previousSearchText = (Math.random() + 1)
              .toString(36)
              .substring(2);
            closeAllLists();
          }
        } else if (e.keyCode === 8) {
          // if backspce is pressed
          // console.log('here running bspace')
          //* removing event listener
          inp.removeEventListener("keydown", keydownEventListener);
          currentFocus = -1;

          if (inp.innerText.search("/") === -1) {
            self.cannedTextDetectedFlag = false;
            self.previousSearchText = (Math.random() + 1)
              .toString(36)
              .substring(2);
            closeAllLists();
          }
        }
      }
    }

    //* logic to make the item in focus active
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
      x[currentFocus].scrollIntoView(true);
    }
    //* logic to remove the "active" class from all autocomplete items
    function removeActive(x) {
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    //* to close all autocomplete lists in the document, except the one passed as an argument:
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      var inp = document.getElementById("msg");
      // console.log('closing all lists')
      for (var i = 0; i < x.length; i++) {
        if (elmnt !== x[i] && elmnt !== inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
  }
  //* dropdown logic ends here

  uploadFile = (file, path) => {
    console.log(file, path, "file,pathfile,path");
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectName", "chat");
        var type = file.type.includes("image")
          ? "image"
          : file.type.includes("video")
          ? "video"
          : "file";
        formData.append("fileType", type);
        const response = await fetch(
          `https://chatbotupload.appypie.com/saveFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        return response.json().then((data) => {
          if (data.success) {
            resolve(data.data.file_url);
          } else {
            resolve(false);
          }
        });
      } catch (error) {
        console.error("Error fetching chatbot details:", error);
        resolve(false);
      }
    });
  };
  render() {
    var { predictionTypeValue } = this.state;
    let visitorOrChatId = this.context.liveChatDocId;
    let chatPickStatusTransaction = this.state.chatPickStatusTransaction;
    var disab = "";
    if (this.context.isDisableIncomingPanel) {
      disab = "none";
    }
    // changed the style type from const to var
    var style = {
      maxHeight: "100px",
      minHeight: "46px",
      resize: "none",
      overflow: "hidden",
      overflowWrap: "break-word",
    };

    var agentName = "";
    if (this.state.groupChatAgents.length === 1) {
      agentName = this.state.groupChatAgents[0]
        ? this.state.groupChatAgents[0].name
        : " ";
    } else if (this.state.groupChatAgents.length > 1) {
      agentName = this.state.groupChatAgents.length;
    }

    var isPhoneCall =
      this.context.currentVisitor &&
      CHAT_TYPES.indexOf(this.context.currentVisitor.start_url) !== -1
        ? true
        : false;
    var isVoipCall =
      this.context.currentVisitor.start_url === "voip_call" ? true : false;
    var shouldShowSmsOption =
      (this.canCalling === "phone_voip" || this.canCalling === "phone") &&
      this.context.currentVisitor.client_phone &&
      !isVoipCall
        ? true
        : false;
    var showWhatsAppOption =
      this.agent.whatsapp_msg === 1 &&
      this.context.currentVisitor.client_phone &&
      this.context.currentVisitor.start_url === "incoming_whatsapp"
        ? true
        : false;
    var isFBChat =
      this.context.currentVisitor.start_url === "incoming_fb" ? true : false;
    var isTDMChat =
      this.context.currentVisitor.start_url === "incoming_tdm" ? true : false;
    var isInstaChat =
      this.context.currentVisitor.start_url === "incoming_insta" ? true : false;
    console.log("isFBChat >>>", this.context.currentVisitor.start_url);
    // console.log('isInstaChat >>>', isInstaChat)

    var selectedOption;
    var emojiField = "msg";
    if (isFBChat) {
      selectedOption = "Send as messanger";
    } else if (isInstaChat) {
      selectedOption = "Send as messanger";
    } else if (isTDMChat) {
      selectedOption = "Send as twitter";
    } else if (this.state.chatTypes === "whatsapptemplate") {
      selectedOption = "Send WhatsApp Template";
    } else if (this.state.chatTypes === "sms") {
      selectedOption = "Send as SMS";
    } else if (
      this.state.chatTypes === "whatsapp" &&
      ((this.state.clientPlan && this.state.clientPlan.status === "data") ||
        showWhatsAppOption)
    ) {
      selectedOption = "Send as whatsapp";
      emojiField = "txtWhatsapp";
    } else {
      if (isPhoneCall && shouldShowSmsOption) {
        if (this.context.currentVisitor.start_url === "incoming_whatsapp") {
          selectedOption = "Send as whatsapp";
          emojiField = "txtWhatsapp";
        } else {
          selectedOption = "Send as SMS";
        }
      } else if (isPhoneCall && !shouldShowSmsOption) {
        selectedOption = "Send as whatsapp";
        emojiField = "txtWhatsapp";
      } else {
        if (this.context.currentVisitor.start_url === "incoming_whatsapp") {
          selectedOption = "Send as whatsapp";
          emojiField = "txtWhatsapp";
        } else {
          selectedOption = "Send as chat";
        }
      }
    }

    var listView = "";
    if (this.state.cannedList.length === 1) {
      listView = "list-1";
    }
    if (this.state.cannedList.length === 2) {
      listView = "list-2";
    }

    // if (this.state.chatPickStatusTransaction) {
    // 	if (this.context.isAutoTicketing && this.state.chatPickStatusTransaction.status === "close") {
    // 		var myArr = [{ key: "isCloseBtnClicked", value: "" }, { key: "rightSectionItemToShow", value: "ticketing" }];
    // 		this.context.updateGlobalData("array", myArr);
    // 	}
    // }

    var attachmentAccepted = "";
    if (emojiField === "txtWhatsapp") {
      attachmentAccepted =
        "image/jpg,image/jpeg,image/png,video/mp4,application/pdf";
    } else if (isInstaChat) {
      attachmentAccepted = "image/jpg,image/jpeg,image/png";
    } else {
      attachmentAccepted = "image/*,video/*,application/pdf";
    }

    return (
      <>
        {!chatPickStatusTransaction ? null : (
          <>
            {chatPickStatusTransaction.status === "chatting" && !isPhoneCall ? (
              <>
                <div
                  style={{ display: "none" }}
                  className="newApp chat-files hide-scrollbar px-lg-8"
                  id="App"
                >
                  {/* {this.context.paymentDetails.paymentStatus ? ( */}
                  <>
                    <Dropzone
                      accept={
                        emojiField === "txtWhatsapp"
                          ? "image/jpg,image/jpeg,image/png,video/mp4,application/pdf"
                          : ""
                      }
                      onDragLeave={this.onDragLeave}
                      onDrop={(files, rejectedFiles, event) =>
                        this.onDrop(files, rejectedFiles, event)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          onMouseMove={this.onDragLeave}
                          onMouseLeave={this.onDragLeave}
                          id="dropZoneSection"
                        >
                          <div className="dropFileDiv" {...getRootProps()}>
                            <p className="drag-para">
                              Drag and drop files here.
                            </p>
                            <input {...getInputProps()} id="fileUploader" />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div
                      className="newfileUploadersTiles container-xxl"
                      id="newfileUploadersTiles"
                    >
                      <div className="newInnerFileUploadersTiles dropzone-previews-js form-row py-4">
                        {/* this.state.filesPreview */}
                        {this.state.filesPreviewChats[visitorOrChatId]}
                        <div
                          className="attachedAddButton"
                          onClick={this.handleFileSelect}
                        >
                          <img
                            alt=""
                            src={require("../../../../assets/img/icon/add.svg")}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                  {/* ) : (null)} */}
                </div>

                {this.context.showMidHeader ? (
                  <div
                    className="chat-footer border-top px-lg-2 pt-5 position-relative"
                    id="message-input"
                  >
                    <div className="container-xxl file-type-bg px-4">
                      {this.context.placeHolder ===
                      "Type here to chat" ? null : (
                        <div className="typingDivAni typing-div">
                          {this.context.placeHolder}{" "}
                          <img
                            alt=""
                            src={require("../../../../assets/img/icon/type1.gif")}
                          />
                        </div>
                      )}

                      {shouldShowSmsOption ? (
                        <div className="typingDivAni">
                          <div className="dropdown historey-serch-drp">
                            <span
                              className="dropdown-toggle cursor-pointer pt-3"
                              data-toggle="dropdown"
                            >
                              {selectedOption}
                            </span>
                            <div className="dropdown-menu px-0 py-2">
                              <span
                                className="dropdown-item"
                                onClick={() => this.handleChatType("chat")}
                              >
                                Send as chat
                              </span>
                              <span
                                className="dropdown-item"
                                onClick={() => this.handleChatType("sms")}
                              >
                                Send as SMS
                              </span>
                              {this.agent.whatsapp_msg &&
                              this.context.currentVisitor.client_phone &&
                              this.state.clientPlan &&
                              this.state.clientPlan.status === "data" ? (
                                <span
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.handleChatType("whatsapp")
                                  }
                                >
                                  Send as whatsapp
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {/* <input type="button" id="predictionType" onClick={this.togglePredictionMethod} value={predictionTypeValue}></input> */}
                        </div>
                      ) : (
                        <>
                          {this.agent.whatsapp_msg &&
                          this.context.currentVisitor.client_phone ? (
                            <div className="typingDivAni">
                              <div className="dropdown historey-serch-drp">
                                <span
                                  className="dropdown-toggle cursor-pointer pt-3"
                                  data-toggle="dropdown"
                                >
                                  {selectedOption}
                                </span>
                                <div className="dropdown-menu px-0 py-2">
                                  <span
                                    className="dropdown-item"
                                    onClick={() => this.handleChatType("chat")}
                                  >
                                    Send as chat
                                  </span>
                                  {this.state.clientPlan &&
                                  this.state.clientPlan.status === "data" ? (
                                    <span
                                      className="dropdown-item"
                                      onClick={() =>
                                        this.handleChatType("whatsapp")
                                      }
                                    >
                                      Send as whatsapp
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              {/* <input type="button" id="predictionType" onClick={this.togglePredictionMethod} value={predictionTypeValue}></input> */}
                            </div>
                          ) : // <div className="typingDivAni"><input type="button" id="predictionType" onClick={this.togglePredictionMethod} value={predictionTypeValue}></input></div>
                          null}
                        </>
                      )}

                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        <div
                          style={{
                            visibility: "hidden",
                            position: "absolute",
                            zIndex: 100,
                          }}
                          className="newemojis"
                          id="emojis"
                        >
                          <div className="newEmojisSectionInner">
                            <Picker
                              title="Pick your emoji…"
                              set="apple"
                              emoji="point_up"
                              onClick={(e) => this.emojiText(e, emojiField)}
                              emojiSize={24}
                              color="#000"
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group magSuggest"
                          >
                            {selectedOption === "Send as chat" ? (
                              <div className="input-group-append">
                                <button
                                  id="emojiopen"
                                  onClick={this.showEmojis}
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-smile injected-svg"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                    <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="15.01"
                                      y2="9"
                                    ></line>
                                  </svg>
                                </button>
                                <button
                                  style={{ display: "none" }}
                                  id="emojiclose"
                                  onClick={this.closeEmojis}
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-smile injected-svg"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                    <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="15.01"
                                      y2="9"
                                    ></line>
                                  </svg>
                                </button>
                              </div>
                            ) : null}

                            {phrasePosition === "innerofdiv" &&
                            selectedOption === "Send as chat" ? (
                              <div className={"textviewSuggest suggest-open"}>
                                <div
                                  id="msg"
                                  name="msg"
                                  contentEditable="true"
                                  suppressContentEditableWarning="true"
                                  className="form-control bg-transparent border-0 textAreaChat2"
                                  placeholder="Type your message..."
                                  rows="1"
                                  data-emoji-input=""
                                  role="textbox"
                                  onKeyDown={this.checkEnterTab}
                                  onKeyUp={this.checkCannedSmartCompose}
                                  onBlur={() => this.draftMessages("msg")}
                                  onFocus={() => this.removeReadCounts("msg")}
                                  onPaste={this.pasteData}
                                  onClick={() => {
                                    this.currentCursorPos =
                                      document.getSelection().anchorOffset;
                                  }}
                                  onChange={() => {
                                    this.currentCursorPos =
                                      this.getCaretPositionCanned();
                                  }}
                                  tabIndex="0"
                                ></div>
                              </div>
                            ) : null}

                            {/* {phrasePosition !== "innerofdiv" && selectedOption === "Send as chat" && <div className="form-control bg-transparent textAreaChat cstmSmartCompose" id={ this.state.editorId } ref={ this.editorContainer } onBlur={() => this.draftMessages("msg")} onFocus={this.removeReadCounts} onPaste={this.pasteData} onKeyDown={this.checkEnterTab} />} */}

                            {/* {phrasePosition === "innerofdiv" &&  selectedOption === "Send as chat" && <div className="form-control bg-transparent textAreaChat cstmSmartCompose" placeholder="Type your message..." id={ this.state.editorId } contentEditable="true" onBlur={() => this.draftMessages("msg")} onFocus={this.removeReadCounts} onPaste={this.pasteData} onKeyDown={this.checkEnterTab} />} */}

                            {/* {selectedOption === "Send as chat" ? (<textarea id="msg" name="msg" onBlur={() => this.draftMessages("msg")} onFocus={this.removeReadCounts} onPaste={this.pasteData} onKeyDown={this.checkEnterTab} className="form-control bg-transparent border-0 textAreaChat" placeholder="Type your message..." rows="1" data-emoji-input="" style={style}></textarea>) : (null)} */}

                            {selectedOption === "Send as SMS" ? (
                              <textarea
                                id="txtSms"
                                name="txtSms"
                                onKeyDown={this.checkSmsEnter}
                                onFocus={() => this.removeReadCounts("txtSms")}
                                className="form-control bg-transparent border-0 textAreaChat"
                                placeholder="Type your sms..."
                                rows="1"
                                data-emoji-input=""
                                style={style}
                              ></textarea>
                            ) : null}

                            {selectedOption === "Send as whatsapp" ? (
                              <>
                                <div className="input-group-append">
                                  <button
                                    id="emojiopen"
                                    onClick={this.showEmojis}
                                    className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                    type="button"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-smile injected-svg"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="9.01"
                                        y2="9"
                                      ></line>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="15.01"
                                        y2="9"
                                      ></line>
                                    </svg>
                                  </button>
                                  <button
                                    style={{ display: "none" }}
                                    id="emojiclose"
                                    onClick={this.closeEmojis}
                                    className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                    type="button"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-smile injected-svg"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                      <line
                                        x1="9"
                                        y1="9"
                                        x2="9.01"
                                        y2="9"
                                      ></line>
                                      <line
                                        x1="15"
                                        y1="9"
                                        x2="15.01"
                                        y2="9"
                                      ></line>
                                    </svg>
                                  </button>
                                </div>
                                <textarea
                                  id="txtWhatsapp"
                                  name="txtWhatsapp"
                                  onKeyDown={this.checkWhatsappEnter}
                                  onFocus={() =>
                                    this.removeReadCounts("txtWhatsapp")
                                  }
                                  className="form-control bg-transparent border-0 textAreaChat"
                                  placeholder="Type your message..."
                                  rows="1"
                                  data-emoji-input=""
                                  style={style}
                                ></textarea>
                              </>
                            ) : null}

                            {// this.context.paymentDetails.paymentStatus &&
                            selectedOption === "Send as chat" ||
                            selectedOption === "Send as whatsapp" ? (
                              <div className="input-group-append">
                                <button
                                  onClick={this.handleFileSelect}
                                  id="chat-upload-btn-1"
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-paperclip injected-svg"
                                  >
                                    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-auto">
                          {selectedOption === "Send as chat" ? (
                            <button
                              id="addmessage"
                              onClick={this.sendChatMessage}
                              className="btn btn-ico btn-primary rounded-circle"
                              type="button"
                            >
                              <img
                                alt=""
                                src={require("../../../../assets/images/livechat/send.svg")}
                              />
                            </button>
                          ) : null}

                          {selectedOption === "Send as SMS" ? (
                            <button
                              id="addsms"
                              onClick={this.sendPhoneSms}
                              className="btn btn-ico btn-primary rounded-circle"
                              type="button"
                            >
                              <img
                                alt=""
                                src={require("../../../../assets/images/livechat/send.svg")}
                              />
                            </button>
                          ) : null}

                          {selectedOption === "Send as whatsapp" ? (
                            <button
                              id="addwhatsapp"
                              onClick={this.sendWhatsappMessage}
                              className="btn btn-ico btn-primary rounded-circle"
                              type="button"
                            >
                              <img
                                alt=""
                                src={require("../../../../assets/images/livechat/send.svg")}
                              />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Chat />
                )}
              </>
            ) : null}
          </>
        )}

        {!chatPickStatusTransaction ? null : (
          <>
            {chatPickStatusTransaction.status === "chatting" && isPhoneCall ? (
              <>
                {/* Dropzone fro attachment start */}
                <div
                  style={{ display: "none" }}
                  className="newApp chat-files hide-scrollbar px-lg-8"
                  id="App"
                >
                  {this.context.paymentDetails.paymentStatus ? (
                    <>
                      <Dropzone
                        accept={attachmentAccepted}
                        onDragLeave={this.onDragLeave}
                        onDrop={(files, rejectedFiles, event) =>
                          this.onDrop(files, rejectedFiles, event)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section
                            onMouseMove={this.onDragLeave}
                            onMouseLeave={this.onDragLeave}
                            id="dropZoneSection"
                          >
                            <div className="dropFileDiv" {...getRootProps()}>
                              <p className="drag-para">
                                Drag and drop files here.
                              </p>
                              <input {...getInputProps()} id="fileUploader" />
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      <div
                        className="newfileUploadersTiles container-xxl"
                        id="newfileUploadersTiles"
                      >
                        <div className="newInnerFileUploadersTiles dropzone-previews-js form-row py-4">
                          {this.state.filesPreviewChats[visitorOrChatId]}
                          <div
                            className="attachedAddButton"
                            onClick={this.handleFileSelect}
                          >
                            <img
                              alt=""
                              src={require("../../../../assets/img/icon/add.svg")}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {/* Dropzone fro attachment end */}

                <div
                  className="chat-footer border-top px-lg-2"
                  id="message-input"
                >
                  <div className="container-xxl file-type-bg px-4">
                    <div className="d-flex align-items-center w-100 flex-wrap newdesign">
                      {this.agent.whatsapp_msg &&
                      this.context.currentVisitor.client_phone ? (
                        <div className="typingDivAni">
                          <div className="dropdown historey-serch-drp  rounded">
                            <span
                              className="dropdown-toggle cursor-pointer p-3"
                              data-toggle="dropdown"
                            >
                              {selectedOption}
                            </span>
                            <div className="dropdown-menu px-0 py-2">
                              {shouldShowSmsOption ? (
                                <span
                                  className={`${
                                    selectedOption === "Send as SMS"
                                      ? "active"
                                      : ""
                                  } dropdown-item`}
                                  onClick={() => this.handleChatType("sms")}
                                >
                                  Send as SMS
                                </span>
                              ) : null}
                              {showWhatsAppOption ||
                              (this.state.clientPlan &&
                                this.state.clientPlan.status === "data") ? (
                                <span
                                  className={`${
                                    showWhatsAppOption ? "active" : ""
                                  } dropdown-item`}
                                  onClick={() =>
                                    this.handleChatType("whatsapp")
                                  }
                                >
                                  Send as whatsapp
                                </span>
                              ) : null}
                              {this.agent.company_name === "snappy" &&
                              this.context.liveChatDocId.includes("TEMP") ? (
                                <span
                                  className={`${
                                    selectedOption === "Send WhatsApp Template"
                                      ? "active"
                                      : ""
                                  } dropdown-item`}
                                  onClick={() =>
                                    this.handleChatType("whatsapptemplate")
                                  }
                                >
                                  Send WhatsApp Template
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {selectedOption === "Send WhatsApp Template" ? (
                        <>
                          <div className="typingDivAni">
                            <div className="dropdown historey-serch-drp rounded">
                              <span
                                className="dropdown-toggle cursor-pointer p-3"
                                data-toggle="dropdown"
                              >
                                {this.state.whatsAppTemplatesSelected.title ||
                                  "Select a template"}
                              </span>
                              <div className="dropdown-menu px-0 py-2">
                                {this.state.whatsAppTemplates.map(
                                  (template, index) => (
                                    <span
                                      key={index}
                                      className={`${
                                        this.state.whatsAppTemplatesSelected
                                          .id === template.id
                                          ? "active"
                                          : ""
                                      } dropdown-item`}
                                      onClick={() =>
                                        this.handleTemplateSelection(
                                          template.id
                                        )
                                      }
                                    >
                                      {template.title}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <p className="small m-0">
                            {" "}
                            Templates cant be edited.
                          </p>
                        </>
                      ) : null}
                    </div>
                    {selectedOption === "Send as whatsapp" ||
                    selectedOption === "Send WhatsApp Template" ? (
                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        {/* Emoji start */}

                        <div
                          style={{ visibility: "hidden", position: "absolute" }}
                          className="newemojis"
                          id="emojis"
                        >
                          <div className="newEmojisSectionInner">
                            <Picker
                              title="Pick your emoji…"
                              set="apple"
                              emoji="point_up"
                              onClick={(e) => this.emojiText(e, "txtWhatsapp")}
                              emojiSize={24}
                              color="#000"
                            />
                          </div>
                        </div>
                        {/* Emoji End */}

                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group"
                          >
                            <div className="input-group-append">
                              {selectedOption ===
                              "Send WhatsApp Template" ? null : (
                                <button
                                  id="emojiopen"
                                  onClick={this.showEmojis}
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-smile injected-svg"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                    <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="15.01"
                                      y2="9"
                                    ></line>
                                  </svg>
                                </button>
                              )}
                              <button
                                style={{ display: "none" }}
                                id="emojiclose"
                                onClick={this.closeEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                            </div>

                            {selectedOption === "Send WhatsApp Template" ? (
                              <textarea
                                id="txtWhatsapp"
                                name="txtWhatsapp"
                                readOnly
                                // value={this.state.whatsAppTemplatesPreviewText}
                                className="form-control bg-transparent border-0 textAreaChat"
                                rows="1"
                                data-emoji-input=""
                                placeholder="Please Select a template to preview message."
                                style={{
                                  ...style,
                                  overflowY: "auto",
                                  // Ensure color is set to a visible value
                                }}
                              ></textarea>
                            ) : (
                              <textarea
                                id="txtWhatsapp"
                                name="txtWhatsapp"
                                onKeyDown={this.checkWhatsappEnter}
                                onFocus={() =>
                                  this.removeReadCounts("txtWhatsapp")
                                }
                                className="form-control bg-transparent border-0 textAreaChat"
                                placeholder="Type your message..."
                                rows="1"
                                data-emoji-input=""
                                style={style}
                              ></textarea>
                            )}

                            {selectedOption ===
                            "Send WhatsApp Template" ? null : (
                              // Attachment Icon
                              <div className="input-group-append">
                                <button
                                  onClick={this.handleFileSelect}
                                  id="chat-upload-btn-1"
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-paperclip injected-svg"
                                  >
                                    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                  </svg>
                                </button>
                              </div>
                            )}
                            {/* Attachment Icon */}
                          </div>
                        </div>
                        <div className="col-auto">
                          <div>
                            {selectedOption === "Send WhatsApp Template" ? (
                              <button
                                id="sendTemplate"
                                onClick={this.sendWhatsAppAgentTemplate}
                                className="btn btn-ico btn-primary rounded-circle"
                                type="button"
                              >
                                <img
                                  alt=""
                                  src={require("../../../../assets/images/livechat/send.svg")}
                                />
                              </button>
                            ) : (
                              <button
                                id="addwhatsapp"
                                onClick={this.sendWhatsappMessage}
                                className="btn btn-ico btn-primary rounded-circle"
                                type="button"
                              >
                                <img
                                  alt=""
                                  src={require("../../../../assets/images/livechat/send.svg")}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {selectedOption === "Send as SMS" ? (
                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group"
                          >
                            <textarea
                              id="txtSms"
                              name="txtSms"
                              onKeyDown={this.checkSmsEnter}
                              onFocus={() => this.removeReadCounts("txtSms")}
                              className="form-control bg-transparent border-0 textAreaChat"
                              placeholder="Type your sms..."
                              rows="1"
                              data-emoji-input=""
                              style={style}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            id="addsms"
                            onClick={this.sendPhoneSms}
                            className="btn btn-ico btn-primary rounded-circle"
                            type="button"
                          >
                            <img
                              alt=""
                              src={require("../../../../assets/images/livechat/send.svg")}
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {selectedOption === "Send as messanger" ? (
                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        {/* Emoji start */}
                        <div
                          style={{ visibility: "hidden", position: "absolute" }}
                          className="newemojis"
                          id="emojis"
                        >
                          <div className="newEmojisSectionInner">
                            <Picker
                              title="Pick your emoji…"
                              set="apple"
                              emoji="point_up"
                              onClick={(e) => this.emojiText(e, "txtMessanger")}
                              emojiSize={24}
                              color="#000"
                            />
                          </div>
                        </div>
                        {/* Emoji End */}

                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group"
                          >
                            {/* Emoji start */}
                            <div className="input-group-append">
                              <button
                                id="emojiopen"
                                onClick={this.showEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                              <button
                                style={{ display: "none" }}
                                id="emojiclose"
                                onClick={this.closeEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                            </div>
                            {/* Emoji End */}

                            <textarea
                              id="txtMessanger"
                              name="txtMessanger"
                              onBlur={() => this.draftMessages("txtMessanger")}
                              onFocus={this.removeReadCounts("txtMessanger")}
                              onKeyDown={this.checkMessangerEnter}
                              className="form-control bg-transparent border-0 textAreaChat"
                              placeholder="Type your message..."
                              rows="1"
                              data-emoji-input=""
                              style={style}
                            ></textarea>

                            {isInstaChat && (
                              <div className="input-group-append">
                                <button
                                  onClick={this.handleFileSelect}
                                  id="chat-upload-btn-1"
                                  className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable btn-cus"
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-paperclip injected-svg"
                                  >
                                    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                  </svg>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            id="addmessanger"
                            onClick={this.sendMessangerMessage}
                            className="btn btn-ico btn-primary rounded-circle"
                            type="button"
                          >
                            <img
                              alt=""
                              src={require("../../../../assets/images/livechat/send.svg")}
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {selectedOption === "Send as insta" ? (
                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        {/* Emoji start */}
                        <div
                          style={{ visibility: "hidden", position: "absolute" }}
                          className="newemojis"
                          id="emojis"
                        >
                          <div className="newEmojisSectionInner">
                            <Picker
                              title="Pick your emoji…"
                              set="apple"
                              emoji="point_up"
                              onClick={(e) => this.emojiText(e, "txtMessanger")}
                              emojiSize={24}
                              color="#000"
                            />
                          </div>
                        </div>
                        {/* Emoji End */}

                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group"
                          >
                            {/* Emoji start */}
                            <div className="input-group-append">
                              <button
                                id="emojiopen"
                                onClick={this.showEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                              <button
                                style={{ display: "none" }}
                                id="emojiclose"
                                onClick={this.closeEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                            </div>
                            {/* Emoji End */}

                            <textarea
                              id="txtMessanger"
                              name="txtMessanger"
                              onBlur={() => this.draftMessages("txtMessanger")}
                              onFocus={this.removeReadCounts("txtMessanger")}
                              onKeyDown={this.checkMessangerEnter}
                              className="form-control bg-transparent border-0 textAreaChat"
                              placeholder="Type your message..."
                              rows="1"
                              data-emoji-input=""
                              style={style}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            id="addmessanger"
                            onClick={this.sendMessangerMessage}
                            className="btn btn-ico btn-primary rounded-circle"
                            type="button"
                          >
                            <img
                              alt=""
                              src={require("../../../../assets/images/livechat/send.svg")}
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {selectedOption === "Send as twitter" ? (
                      <div className="form-row align-items-center py-3 py-lg-5 position-relative">
                        {/* Emoji start */}
                        <div
                          style={{ visibility: "hidden", position: "absolute" }}
                          className="newemojis"
                          id="emojis"
                        >
                          <div className="newEmojisSectionInner">
                            <Picker
                              title="Pick your emoji…"
                              set="apple"
                              emoji="point_up"
                              onClick={(e) => this.emojiText(e, "txtTwitterDM")}
                              emojiSize={24}
                              color="#000"
                            />
                          </div>
                        </div>
                        {/* Emoji End */}

                        <div className="col">
                          <div
                            style={{ pointerEvents: disab }}
                            className="input-group"
                          >
                            {/* Emoji start */}
                            <div className="input-group-append">
                              <button
                                id="emojiopen"
                                onClick={this.showEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                              <button
                                style={{ display: "none" }}
                                id="emojiclose"
                                onClick={this.closeEmojis}
                                className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 btn-cus"
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-smile injected-svg"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                  <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                  <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                </svg>
                              </button>
                            </div>
                            {/* Emoji End */}

                            <textarea
                              id="txtTwitterDM"
                              name="txtTwitterDM"
                              onBlur={() => this.draftMessages("txtTwitterDM")}
                              onFocus={this.removeReadCounts("txtTwitterDM")}
                              onKeyDown={this.checkTwitterDMEnter}
                              className="form-control bg-transparent border-0 textAreaChat"
                              placeholder="Type your message..."
                              rows="1"
                              data-emoji-input=""
                              style={style}
                            ></textarea>

                            {/* Attachment Icon */}
                            {/* {this.context.paymentDetails.paymentStatus && <div className="input-group-append">
												<button onClick={this.handleFileSelect} id="chat-upload-btn-1" className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable btn-cus" type="button">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-paperclip injected-svg"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
												</button>
											</div>} */}
                            {/* Attachment Icon */}
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            id="addtwitterdm"
                            onClick={this.sendTwitterDMMessage}
                            className="btn btn-ico btn-primary rounded-circle"
                            type="button"
                          >
                            <img
                              alt=""
                              src={require("../../../../assets/images/livechat/send.svg")}
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}

        {!chatPickStatusTransaction ? null : (
          <>
            {chatPickStatusTransaction.status === "close" ? (
              <div
                className="chat-footer border-top px-lg-8 bg-basic"
                id="message-input"
              >
                <div
                  id="endChatShadow"
                  className="container end-chat-shadow"
                ></div>
                {this.context.showDeskTicketPopUp ? (
                  <div
                    className="trialPopup fixed"
                    style={{ position: "absolute", zIndex: 5, right: "0px" }}
                  >
                    <div className="trialPopupBody">
                      <span
                        className="closeBtn"
                        onClick={() => {
                          this.context.updateGlobalData(
                            "showDeskTicketPopUp",
                            false
                          );
                        }}
                      ></span>
                      <img
                        src={require("../../../../assets/images/free_trial.svg")}
                        alt="Close Desk Ticketing Window"
                      />
                      <h4 className="mb-3">
                        Appy Pie Desk Ticket Window is Open!
                      </h4>
                      <p className="mb-3">
                        Do you want to close the chat and the ticket window?
                        Ticket will not be created...
                      </p>
                      <div className="" style={{ display: "flex" }}>
                        <button
                          className="btnBlueSettings mr-4"
                          onClick={() => {
                            this.endChat(visitorOrChatId, "closeTicketing");
                            this.context.updateGlobalData(
                              "showDeskTicketPopUp",
                              false
                            );
                          }}
                        >
                          Yes
                        </button>
                        <button
                          className="btnBlueSettings"
                          onClick={() => {
                            this.context.updateGlobalData(
                              "showDeskTicketPopUp",
                              false
                            );
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="container-xxl file-type-bg">
                  <div className="form-row align-items-center py-2 py-lg-3 position-relative text-center">
                    <div className="visitorEnded ml-auto mr-auto">
                      <h6 className="mb-2">
                        {this.context.chatPickStatusTransaction.msgToShow}.
                      </h6>
                      <button
                        onClick={() => this.endChat(visitorOrChatId)}
                        className="startChatBtn btnBlueSettings"
                      >
                        {chatPickStatusTransaction.Text}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        {!chatPickStatusTransaction ? null : (
          <>
            {chatPickStatusTransaction.status === "start" ? (
              <div
                className="chat-footer border-top px-lg-8 bg-basic"
                id="message-input"
              >
                {this.context.incoming_guide === false &&
                this.context.incoming_guide_step === 1 ? (
                  <div className="overlay-guide"></div>
                ) : null}
                <div
                  className={
                    this.context.incoming_guide === false &&
                    this.context.incoming_guide_step === 1
                      ? "startChatNew position-top"
                      : "startChatNew"
                  }
                >
                  {this.context.incoming_guide === false &&
                  this.context.incoming_guide_step === 1 ? (
                    <div className="tour_guide">
                      <p className="title">Step 2 of 3</p>
                      <p className="text-center">
                        Click on this button to pick the chat.
                      </p>
                      <button
                        className="btn-next"
                        onClick={() => {
                          this.guidenextStep(
                            "incoming",
                            2,
                            "incoming_guide_step"
                          );
                        }}
                      >
                        Next
                      </button>
                      <button
                        className="end-btn"
                        onClick={() => {
                          this.endGuideTour("incoming", "incoming_guide");
                        }}
                      >
                        End tour
                      </button>
                    </div>
                  ) : null}
                  <button
                    disabled={
                      this.state.startBtnText === "Starting..." ? true : false
                    }
                    onClick={() => this.startChat(visitorOrChatId)}
                    className="startChatBtn btnBlueSettings"
                  >
                    {this.state.startBtnText
                      ? this.state.startBtnText
                      : chatPickStatusTransaction.Text}
                  </button>
                </div>
              </div>
            ) : null}
          </>
        )}

        {!chatPickStatusTransaction ? null : (
          <>
            {chatPickStatusTransaction.status === "serving" ? (
              <div
                className="chat-footer border-top px-lg-8 bg-basic"
                id="message-input"
              >
                <div className="container-xxl file-type-bg">
                  <div className="form-row align-items-center py-2 py-lg-3 position-relative text-center">
                    <div className="visitorEnded ml-auto mr-auto">
                      {this.state.groupChatAgents.length > 1 ? (
                        <h6 className="mb-2">
                          Visitor currently being served by {agentName} agents{" "}
                        </h6>
                      ) : (
                        <h6 className="mb-2">
                          Visitor currently being served by agent {agentName}{" "}
                        </h6>
                      )}
                      {this.context.currentVisitor.client_status === "online" &&
                      (this.userRole === "admin" ||
                        this.userRole === "owner") ? (
                        <button
                          disabled={
                            this.state.startBtnText === "Starting..."
                              ? true
                              : false
                          }
                          onClick={() => this.joinChat(visitorOrChatId)}
                          className="startChatBtn btnBlueSettings"
                        >
                          {this.state.startBtnText
                            ? this.state.startBtnText
                            : chatPickStatusTransaction.Text}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        {/* Hashtag popup */}
        {/* {this.state.showCannedResp && this.state.cannedList.length > 0 ? (<div className="cannedPop chatHaspop">
                    <div className="CPopupBody hashtagPop">
                        <ul>
							{ this.state.cannedList.map((list, index) => 
							
							(
								<li key={index}><span className="hashtagText" onClick={() => {this.replaceCanned(list)}}> {list.canned_hashtag}</span> <span><img src={require("../../../../assets/img/icon/downarrow.svg")} /></span></li>
							)) }
                            
                        </ul>
                        <div className="hashtagFooter">
                            <span className="cursor-pointer" onClick={() => {this.setState({showAddCanned: true})}}>+ Add new text</span>
                        </div>
                    </div>
                </div>) : (null)} */}

        {/* Add canned text popup */}
        {this.state.showAddCanned ? (
          <div className="cannedPop">
            <div className="CPopupBody">
              <span className="heder-icon-img" onClick={this.hideAddCanned}>
                <img
                  src={require("../../../../assets/img/icon/right-panel-crose.svg")}
                />
              </span>

              <div className="addArea">
                <label> Add new canned text </label>
                <div className="input-group material-input mb-5">
                  <TextField
                    className="form-control w-100"
                    rows={3}
                    id="canned_text"
                    value={this.state.cannedText}
                    onChange={(e) => {
                      this.setState({ cannedText: e.target.value });
                    }}
                    label="Type your message"
                    variant="outlined"
                    multiline
                  />
                </div>
              </div>
              <div className="addArea">
                <label>Applicable on</label>
                <div className="d-flex mb-5">
                  <div className="settings-feld k-flex align-items-center my-5">
                    <span className="main-checkbox-all">
                      <label className="containerRadio containerafterchecked">
                        <input
                          type="radio"
                          value="0"
                          name="applicable_on"
                          checked={
                            this.state.cannedApplicableOn === 0 ? true : false
                          }
                          onChange={() => {
                            this.setState({ cannedApplicableOn: 0 });
                          }}
                        />
                        <span className="radioCheckmark"></span>
                      </label>
                    </span>
                    <h6 className="card-title mb-0 pr-3">Everyone</h6>
                  </div>
                  <div className="settings-feld k-flex align-items-center my-4">
                    <span className="main-checkbox-all">
                      <label className="containerRadio containerafterchecked">
                        <input
                          type="radio"
                          value="1"
                          name="applicable_on"
                          checked={
                            this.state.cannedApplicableOn === 1 ? true : false
                          }
                          onChange={() => {
                            this.setState({ cannedApplicableOn: 1 });
                          }}
                        />
                        <span className="radioCheckmark"></span>
                      </label>
                    </span>
                    <h6 className="card-title mb-0 pr-3">Me</h6>
                  </div>
                </div>
              </div>
              <div className="addArea">
                <label>Shortcut*</label>
                <div className="input-group material-input mb-5">
                  <TextField
                    className="form-control w-100"
                    label="#"
                    variant="outlined"
                    value={this.state.cannedHashTag}
                    onChange={(e) =>
                      this.setState({ cannedHashTag: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="d-flex mt-7">
                <button
                  className="btnBlueSettings mr-2"
                  onClick={this.saveCanned}
                >
                  Save
                </button>
                <button
                  className="btnBorderSettings ml-2"
                  onClick={this.hideAddCanned}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default React.memo(Footer);
